<template>
  <div class="login-page">
    <div class="content">
      <div class="logo">
        <div class="logo-left">
          <img src="../assets/images/logo.png" />
          <span style="font-family:cursive">密讯数据</span>
        </div>
      </div>
      <div class="login">
        <div class="imgbox">
          <img src="../assets/images/login_img.png" />
        </div>
        <div class="login-message">
          <div class="title">账号登录</div>
          <div class="formbox">
            <div class="inputbox">
              <input
                type="text"
                placeholder="请输入用户名"
                class="name"
                v-model="phoneNumber"
              />
            </div>
            <div class="inputbox">
              <input
                type="password"
                placeholder="请输入密码"
                class="password"
                v-model="passWord"
                @keyup.enter="goLogin"
              />
            </div>
            <div class="verify" v-show="showVerify" v-text="verifyText"></div>
            <div class="btnbox">
              <button class="accountLogin" v-if="accountLogin" @click="goLogin">
                立即登录
              </button>
              <button class="accountLogin_ing" v-else>登录中...</button>
            </div>
          </div>
          <div class="tips">
            温馨提示：建议使用谷歌、火狐、360极速模式浏览器访问！
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="copy">
        <div class="media">
          <img src="../assets/images/service_telephon.png" />
          <div class="media-body">
            <p>客服电话</p>
            <p>13524449221 (周一至周五24小时服务)</p>
          </div>
        </div>
        <div class="media">
          <img src="../assets/images/service_mailbox.png" />
          <div class="media-body">
            <p>客服邮箱</p>
            <p>1048084657@qq.com</p>
          </div>
        </div>
        <div class="media">
          <img src="../assets/images/company_address.png" />
          <div class="media-body">
            <p>公司地址</p>
            <p>上海市嘉定区嘉年路128弄1号楼404</p>
          </div>
        </div>
        <div class="media qr-code">
          <!-- <img src="../assets/images/qr_code.png" /> -->
        </div>
      </div>
    </div>
    <div class="footer1">
      <a class="copyright">Copyright © 2021. All rights reserved.</a>
      <a href="http://www.beian.miit.gov.cn/" class="copyright1"> </a>
      <a class="flex"></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    alertInfo: String
  },
  data() {
    return {
      accountLogin: true,
      phoneNumber: "",
      passWord: "",
      showVerify: this.alertInfo ? true : true,
      verifyText: this.alertInfo
    };
  },
  methods: {
    goLogin() {
      if (this.phoneNumber === "") {
        this.showVerify = true;
        this.verifyText = "请输入用户名！";
      } else if (this.passWord === "") {
        this.showVerify = true;
        this.verifyText = "请输入登录密码！";
      } else {
        this.accountLogin = false;
        let requestData = {
          username: this.phoneNumber,
          password: this.passWord,
          grant_type: "password",
          client_id: "web",
          client_secret: "angsheng",
          scope: "web"
        };
        let that = this;
        sessionStorage.removeItem("token");
        this.$request.post("/oauth/token", requestData).then(res => {
          that.accountLogin = true;
          if (res.data.error_description) {
            this.showVerify = true;
            this.verifyText = res.data.error_description;
          } else {
            that.setToken(res.data);
          }
        });
      }
    },
    setToken(token) {
      let authorization = token.token_type + " " + token.access_token;
      sessionStorage.setItem("token", authorization);
      this.$request.get("/api/user").then(res => {
        sessionStorage.setItem("currentUser", JSON.stringify(res.data));
        this.$router.push("/");
      });
    }
  }
};
</script>
<style lang="scss">
.login-page {
  background: url(../assets/images/login-bg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: calc(100vh - 117px);
  // width: 488px;
  // height: 400px;
  // background: #fff;
  // margin: 100px auto;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10vw;
    .logo {
      padding-top: 30px;
      .logo-left {
        text-align: left;
        display: flex;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }
        span {
          color: #fff;
          font-size: 40px;
        }
      }
    }
    .login {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .imgbox {
        width: 40%;
        img {
          width: 100%;
        }
      }
      .login-message {
        width: 30%;
        background: #fff;
        padding: 40px 65px;
        box-sizing: border-box;
      }
    }
    .title {
      margin-bottom: 20px;
      font-size: 24px;
      color: #1f2d59;
    }
    .formbox {
      .inputbox {
        input {
          width: 100%;
          height: 40px;
          margin-bottom: 20px;
          padding-left: 12px;
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
        }
      }
      .verify {
        font-size: 12px;
        color: #ec454f;
      }
      .btnbox {
        margin: 10px 0;
        button {
          width: 100%;
          height: 40px;
          background: #193059;
          color: #fff;
          font-size: 14px;
        }
      }
    }
    .tips {
      font-size: 12px;
      color: #999;
      text-align: left;
      margin-top: 20px;
    }
  }
  .footer1 {
    font-size: 12px;
    color: #8a9098;
    width: 100%;
    background: #00040a;
    box-sizing: border-box;
    padding: 0 15vw;
    position: fixed;
    bottom: 0;
    height: 37px;
    display: flex;
    align-items: center;
    text-align: center;
    a {
      color: #8a9098;
      text-decoration: none;
    }
    .copyright {
      margin-right: 24px;
    }
    .copyright1 {
      margin-right: 5px;
    }
  }
  .footer {
    width: 100%;
    background: #00040a;
    box-sizing: border-box;
    padding: 0 15vw;
    position: fixed;
    bottom: 37px;
    height: 80px;

    .copy {
      height: 100%;
      border-bottom: 1px solid #1d2432;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .media {
        display: flex;
        img {
          width: 40px;
          height: 40px;
          margin-right: 16px;
        }
        .media-body {
          font-size: 12px;
          color: #e2e2e2;
          text-align: left;
          p {
            margin: 0;
            padding: 0;
            &:first-child {
              margin-bottom: 5px;
            }
          }
        }
        &.qr-code {
          img {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
}
</style>
