<template>
  <div class="crawlConfigBox">
    <div class="header">
      <el-input
        size="medium"
        v-if="inTypes(['site', 'seedSite', 'se'])"
        v-model="keyword"
        placeholder="请输入搜索关键词"
        suffix-icon="el-icon-search"
        @keyup.enter.native="search"
      >
      </el-input>
      <button @click="updateCrawlConfig()">增加</button>
      <button
        v-if="inTypes(['site'])"
        @click="
          siteInfoForm = {};
          siteInfoDialog = true;
        "
      >
        导航增加
      </button>
    </div>
    <div class="content">
      <div class="right">
        <el-table :key="timeStamp" :data="tableData" style="width: 100%">
          <el-table-column type="index"> </el-table-column>
          <el-table-column
            v-for="(item, index) in getTableColumn()"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                v-if="inTypes(['site', 'seedSite', 'se'])"
                @click="testCrawlConfig(scope.row)"
                type="text"
                size="small"
                >测试</el-button
              >
              <el-button
                @click="updateCrawlConfig(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                @click="delCrawlConfig(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="pageBean.number"
          :page-size.sync="pageBean.size"
          :page-sizes="[10, 20, 30, 50]"
          layout="total,sizes,prev, pager, next"
          :total.sync="pageBean.totalElements"
        >
        </el-pagination>
        <el-dialog title="修改" :visible.sync="dialogFormVisible">
          <el-form :model="updateObj">
            <el-form-item label="名称" :label-width="formLabelWidth">
              <el-input v-model="updateObj.name" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['key', 'specialKey'])"
              label="任务"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="updateObj.keys"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['specialKey'])"
              label="搜索引擎"
              :label-width="formLabelWidth"
            >
              <el-select placeholder="请选择搜索引擎" v-model="updateObj.se.id">
                <el-option
                  v-for="(item, index) in notCommonSe"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="链接"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="1"
                v-model="updateObj.url"
                autocomplete="off"
                @blur="checkUrlDuplicate"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="inTypes(['seedSite'])"
              label="web页面链接"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="1"
                v-model="updateObj.htmlPageUrl"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="头信息"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="updateObj.header"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'se'])"
              label="提交参数"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="updateObj.postParam"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['seedSite'])"
              label="起点种子"
              :label-width="formLabelWidth"
            >
              <el-input v-model="updateObj.seed" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['seedSite'])"
              label="采集时间间隔"
              :label-width="formLabelWidth"
            >
              <el-input-number
                v-model="updateObj.crawlTime"
                controls-position="right"
                :min="1"
                :max="1000"
                label="采集时间间隔"
              ></el-input-number>
              分钟
            </el-form-item>
            <el-form-item
              v-if="inTypes(['seedSite'])"
              label="单次抓取数量"
              :label-width="formLabelWidth"
            >
              <el-input-number
                v-model="updateObj.crawlSeedNum"
                controls-position="right"
                label="单次抓取数量"
              ></el-input-number>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['seedSite'])"
              label="空页面响应"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.emptyPageFlag"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['seedSite'])"
              label="重试次数"
              :label-width="formLabelWidth"
            >
              <el-input-number
                v-model="updateObj.retryCount"
                controls-position="right"
                label="重试次数"
              ></el-input-number>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="类型"
              :label-width="formLabelWidth"
            >
              <el-select
                placeholder="请选择数据类型"
                v-model="updateObj.category"
              >
                <el-option
                  v-for="(item, index) in docType"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'se'])"
              label="子链接选择器"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.urlCssQuery"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'se'])"
              label="选择器属性(网页链接)"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.attrName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="inTypes(['site', 'se'])"
              label="子链接正则表达式(子请求链接)"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.urlRegex"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['se'])"
              label="网页子链接格式化表达式"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.childHtmlUrlFormat"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site'])"
              label="页内容块"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.blockPath"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="数据标签"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.emptyPageFlag"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="标题选择器"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.titlePath"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="内容选择器"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.cntPath"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="时间选择器"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.timePath"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="作者选择器"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="updateObj.authorPath"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="链接生成代码"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="updateObj.ruleCode"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="内容解析代码"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="updateObj.parserCode"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['specialKey'])"
              label="采集时间间隔"
              :label-width="formLabelWidth"
            >
              <el-input-number
                v-model="updateObj.taskInterval"
                controls-position="right"
                :min="1"
                :max="1000"
                label="采集时间间隔"
              ></el-input-number>
              分钟
            </el-form-item>

            <el-form-item
              v-if="inTypes(['key', 'site'])"
              label="更新速度"
              :label-width="formLabelWidth"
            >
              <el-select v-model="updateObj.level" placeholder="请选择更新速度">
                <el-option label="快" :value="1"></el-option>
                <el-option label="中" :value="2"></el-option>
                <el-option label="慢" :value="3"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['site', 'seedSite', 'se'])"
              label="海外站点"
              :label-width="formLabelWidth"
            >
              <el-switch
                v-model="updateObj.oversea"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['se'])"
              label="非公共采集"
              :label-width="formLabelWidth"
            >
              <el-switch
                v-model="updateObj.notCommon"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['specialKey', 'site', 'seedSite', 'se'])"
              label="状态"
              :label-width="formLabelWidth"
            >
              <el-switch
                v-model="updateObj.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>

            <el-form-item
              v-if="inTypes(['key'])"
              label="状态"
              :label-width="formLabelWidth"
            >
              <el-switch
                v-model="updateObj.state"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button
              type="primary"
              @click="
                dialogFormVisible = false;
                updateCrawl();
              "
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>

    <el-dialog width="40%" title="新增导航网址" :visible.sync="siteInfoDialog">
      <el-form ref="form" :model="siteInfoForm" label-width="100px">
        <el-form-item label="网站名称">
          <el-input v-model="siteInfoForm.name"></el-input>
        </el-form-item>

        <el-form-item label="网站网址">
          <el-input v-model="siteInfoForm.homeUrl"></el-input>
        </el-form-item>

        <el-form-item label="重点网站：" style="text-align:left;">
          <el-switch v-model="siteInfoForm.header"></el-switch>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="
              siteInfoDialog = false;
              addSiteInfo();
            "
            >增加</el-button
          >
          <el-button @click="siteInfoDialog = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
// import { duplicateWords } from "@/utils/api.js";

export default {
  props: {
    checkType: String
  },
  data() {
    return {
      siteInfoForm: {},
      timeStamp: new Date().getTime(),
      keyword: "",
      docType: ["视频", "社交", "新闻", "论坛", "网页"],
      formLabelWidth: "160px",
      updateObj: { se: {}, enable: true, oversea: true },
      dialogFormVisible: false,
      siteInfoDialog: false,
      notCommonSe: [],
      tableData: [],
      crawlType: [
        {
          name: "关键词任务",
          type: "key",
          tableColumn: [
            { prop: "name", label: "任务标签" },
            { prop: "keys", label: "任务" },
            { prop: "level", label: "更新速度" },
            { prop: "state", label: "状态" }
          ]
        },
        {
          name: "特殊搜索任务",
          type: "specialKey",
          tableColumn: [
            { prop: "name", label: "名称" },
            { prop: "keys", label: "任务" },
            { prop: "se", label: "搜索引擎" },
            { prop: "taskInterval", label: "采集间隔" },
            { prop: "enable", label: "状态" }
          ]
        },
        {
          name: "种子任务",
          type: "seedSite",
          tableColumn: [
            { prop: "name", label: "种子名称" },
            { prop: "category", label: "类型" },
            { prop: "url", label: "链接", width: 400 },
            { prop: "seed", label: "起点种子" },
            { prop: "crawlSeedNum", label: "种子数量" },
            { prop: "crawlTime", label: "更新速度" },
            { prop: "crawlNum", label: "采集数据总量" },
            { prop: "enable", label: "状态" }
          ]
        },
        {
          name: "站点任务",
          type: "site",
          tableColumn: [
            { prop: "name", label: "站点名称" },
            { prop: "category", label: "类型" },
            { prop: "url", label: "链接", width: 400 },
            { prop: "level", label: "更新速度" },
            { prop: "crawlNum", label: "采集数量" },
            { prop: "enable", label: "状态" }
          ]
        },
        {
          name: "搜索引擎配置",
          type: "se",
          tableColumn: [
            { prop: "name", label: "名称" },
            { prop: "category", label: "类型" },
            { prop: "url", label: "入口链接", width: 400 },
            { prop: "notCommon", label: "非公共采集" },
            { prop: "crawlNum", label: "采集数量" },
            { prop: "enable", label: "状态" }
          ]
        }
      ],
      pageBean: { number: 1, size: 10, totalElements: 0 }
    };
  },
  created() {
    this.changeCheckType(this.checkType);
  },
  watch: {
    checkType: function(val) {
      this.changeCheckType(val);
    }
  },
  methods: {
    checkUrlDuplicate() {
      //检查连接是否重复
      this.$request
        .get(`/api/crawl/${this.checkType}/123  `, {
          action: "duplicate",
          data: this.updateObj.url
        })
        .then(res => {
          if (res.data > 0) {
            this.$message.warning("该网站已配置!");
          }
        });
    },
    addSiteInfo() {
      this.siteInfoForm.isPublic = true;
      // alert(this.siteInfoForm.header);
      if (this.siteInfoForm.header) {
        delete this.siteInfoForm.header;
        this.siteInfoForm.tag = "header";
      }
      this.siteInfoForm.name = this.siteInfoForm.name.trim();
      this.siteInfoForm.homeUrl = this.siteInfoForm.homeUrl.trim();
      if (!this.siteInfoForm.homeUrl.startsWith("http")) {
        this.siteInfoForm.homeUrl = "http://" + this.siteInfoForm.homeUrl;
      }
      this.$request
        .post("/api/sys/resource/siteInfo", this.siteInfoForm)
        .then(() => {
          this.$message({
            type: "success",
            message: "添加成功!"
          });
        });
    },
    search() {
      if (this.keyword) {
        this.requestTableData(this.keyword);
      } else {
        this.$message.warning("请输入搜索关键词");
      }
      console.log(this.keyword);
    },
    inTypes(types) {
      return types.includes(this.checkType);
    },
    changeCheckType(cType) {
      //更改选中的采集类型
      this.checkType = cType;
      this.keyword = "";
      this.pageBean = { number: 1, size: 10, totalElements: 0 };
      this.requestTableData();
    },
    updateCrawl() {
      // if (this.updateObj.keys) {
      //   this.updateObj.keys = duplicateWords(this.updateObj.keys);
      // }

      this.$request
        .post(`/api/crawl/${this.checkType}`, this.updateObj)
        .then(() => {
          this.$message({
            type: "success",
            message: "修改成功!"
          });
          this.requestTableData();
        });
    },
    updateCrawlConfig(row) {
      //修改抓取任务
      if (this.checkType == "specialKey") {
        //特殊搜索任务 获取非公共采集引擎
        this.$request
          .get("/api/crawl/se", { page: 1, size: 100, common: false })
          .then(res => {
            this.notCommonSe = res.data.content;
          });
      }

      if (row) {
        this.updateObj = this.pageBean.content.filter(item => {
          if (item.id == row.id) {
            if (!item.se) {
              item.se = {};
            } else {
              item.se = { id: item.se.id };
            }
            return item;
          }
        })[0];
      } else {
        this.updateObj = {
          se: {},
          enable: true,
          oversea: true,
          level: 2,
          category: "新闻"
        };
      }
      this.dialogFormVisible = true;
    },

    testCrawlConfig(row) {
      if (this.checkType == "se") {
        //搜索引擎进入关键词获取
        this.$prompt("", "请输入任务", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(({ value }) => {
          //测试配置的任务
          this.$request.get(`/api/crawl/${this.checkType}/${row.id}`, {
            action: "test",
            data: value
          });
        });
      } else {
        //测试配置的任务
        this.$request.get(`/api/crawl/${this.checkType}/${row.id}`, {
          action: "test"
        });
      }
    },
    delCrawlConfig(row) {
      //删除抓取任务
      this.$confirm("此操作将删除任务“" + row.name + "”, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$request
          .delete(`/api/crawl/${this.checkType}/${row.id}`)
          .then(res => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.requestTableData();
          });
      });
    },
    requestTableData(keyword) {
      let requestData = {
        page: this.pageBean.number,
        size: this.pageBean.size
      };
      if (keyword) {
        requestData.keyword = keyword;
      }

      //请求表格数据
      this.$request
        .get("/api/crawl/" + this.checkType, requestData)
        .then(res => {
          res.data.number += 1;
          this.pageBean = JSON.parse(JSON.stringify(res.data));
          this.tableData = this.convertToTableData(res.data.content);
          if (this.inTypes(["site", "seedSite", "se"])) {
            //进行每日采集量查询
            this.tableData.forEach(item => {
              this.$request
                .post("/api/doc/docCount", {
                  field: "source",
                  keyword: item.name
                })
                .then(res => {
                  item.crawlNum = parseInt(res.data);
                  this.timeStamp = new Date().getTime();
                });
            });
          }
        });
    },
    convertToTableData(dbData) {
      //response转换为表格数据
      for (let item of dbData) {
        if ("keys" in item && item.keys.length > 50) {
          item.keys = item.keys.substring(0, 50) + "...";
        }
        if (item.se) {
          item.se = item.se.name;
        }
        if (item.taskInterval) {
          item.taskInterval += "分钟";
        }
        if (item.crawlTime) {
          item.crawlTime += "分钟";
        }

        if ("state" in item) {
          item.state = item.state ? "开启" : "关闭";
        }

        if ("enable" in item) {
          item.enable = item.enable ? "开启" : "关闭";
        }

        if ("notCommon" in item) {
          item.notCommon = item.notCommon ? "是" : "否";
        }

        if ("url" in item && item.url.length > 50) {
          item.url = item.url.substring(0, 50) + "...";
        }

        if ("level" in item) {
          switch (item.level) {
            case 1:
              item.level = "快";
              break;
            case 2:
              item.level = "中";
              break;
            case 3:
              item.level = "慢";
              break;
          }
        }
      }
      return dbData;
    },
    getTableColumn() {
      //获取指定类型的表格栏目
      for (let cType of this.crawlType) {
        if (cType.type == this.checkType) {
          return cType.tableColumn;
        }
      }
    },
    handleCurrentChange(currentPage) {
      //修改当前页数
      this.pageBean.number = currentPage;
      this.requestTableData();
    },
    handleSizeChange(pageSize) {
      //修改当前页大小
      this.pageBean.size = pageSize;
      this.requestTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.crawlConfigBox {
  height: 100%;
  background: #fff;
  overflow: auto;
  .header {
    padding-right: 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-input {
      width: 300px;
      margin-right: 20px;
    }
    button {
      height: 36px;
      width: 80px;
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      background: none;
    }
    // border-bottom: 1px solid #5090f1;
  }
  .content {
    display: flex;
    .left {
      flex: 2;
      margin-right: 10px;
      // border-right: 1px solid #5090f1;
      ul {
        margin: 0;
        padding: 0;
        width: 200px;
        margin: 0 auto;
        list-style: none;
        // border: 1px solid #5090f1;
        li {
          height: 60px;
          line-height: 60px;
          &:hover {
            background: #5090f1;
          }
          span {
            display: inline-block;
            width: 150px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #a1c5eb;
            border-radius: 2px;
          }
        }
      }
    }
    .right {
      padding: 10px;
      flex: 8;
      .el-pagination {
        margin-top: 30px;
      }
    }
  }
}
</style>
