<template>
  <div id="home">
    <div class="header-nav">
      <Header @clickNav="changeNav" />
    </div>
    <div
      v-text="notifyMsg"
      style="color: red;margin-top: 60px;margin-bottom: 10px;"
    ></div>
    <div class="views">
      <router-view :aimsg="aimsg"></router-view>
    </div>
    <audio ref="audio" preload="auto" loop>
      <source src="../assets/y1479.wav" type="audio/ogg" />
    </audio>
    <el-dialog
      class="crawldialog"
      title="抓取测试信息"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">名称:</div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple" v-text="testCrawlInfo.name"></div
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">标题:</div></el-col
        >
        <el-col :span="18"
          ><div
            class="grid-content bg-purple"
            v-text="testCrawlInfo.title"
          ></div
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">内容:</div></el-col
        >
        <el-col :span="18"
          ><div class="grid-content bg-purple" v-html="testCrawlInfo.cnt"></div
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">时间:</div></el-col
        >
        <el-col :span="18"
          ><div class="grid-content bg-purple" v-html="testCrawlInfo.time"></div
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">作者:</div></el-col
        >
        <el-col :span="6"
          ><div
            class="grid-content bg-purple"
            v-text="testCrawlInfo.author"
          ></div
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">链接:</div></el-col
        >
        <el-col :span="18"
          ><a
            class="grid-content bg-purple"
            target="_blank"
            :href="testCrawlInfo.url"
            v-text="testCrawlInfo.url"
          ></a
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">抓取过程:</div></el-col
        >
        <el-col :span="18">
          <div
            class="grid-content bg-purple"
            v-text="testCrawlInfo.processMsg"
          ></div
        ></el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6"
          ><div class="grid-content bg-purple">子链接抓取条目数:</div></el-col
        >
        <el-col :span="18">
          <div class="grid-content bg-purple" v-text="testCrawlInfo.child"></div
        ></el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

import SockJS from "sockjs-client";
import Stomp from "stompjs";

import { resetHotWords, getCurrentUser } from "../utils/api.js";

export default {
  name: "Home",
  components: {
    Header
  },
  data() {
    return {
      dialogVisible: false,
      stompClient: null,
      testCrawlInfo: {},
      aimsg: "",
      notifyMsg: null
    };
  },
  mounted() {
    resetHotWords();
  },
  methods: {
    changeNav(nav) {
      this.$router.push({ name: nav.name });
    },
    goDetail(id) {
      let routeData = this.$router.resolve(`/detail?id=${id}`);
      window.open(routeData.href, "_blank");
    },
    receive_socket_msg(rec_msg) {
      let msgCnt = rec_msg.body;
      if (msgCnt) {
        let msgBody = JSON.parse(msgCnt);
        if (msgBody.type == 1) {
          this.playAlarmMsg(msgBody);
        } else if (msgBody.type == 2) {
          this.testCrawlInfo = msgBody;
          //测试信息
          this.dialogVisible = true;
        } else if (msgBody.type == 3) {
          this.$message.success("下载文件已生成，文件大小" + msgBody.filesize);
          window.location.href = msgBody.downurl;
        } else if (msgBody.type == 4) {
          this.aimsg = msgBody.aimsg;
        }
      }
    },
    playAlarmMsg(msgBody) {
      let alarmTime = localStorage.getItem("alarmTime");
      if (!alarmTime) {
        alarmTime = 6000;
      }
      let that = this;
      this.$notify({
        title: "新预警消息",
        message: msgBody.cnt,
        position: "bottom-right",
        duration: 0,
        iconClass: "el-icon-message-solid",
        onClick: function() {
          this.close();
          that.goDetail(msgBody.id);
        },
        onClose: function() {
          that.$refs.audio.pause();
        }
      });
      if (alarmTime != 0) {
        this.$refs.audio.play();
        if (alarmTime > 0) {
          setTimeout(() => {
            this.$refs.audio.pause();
          }, alarmTime);
        }
      }
    },
    reconnectWebsock(sockUrl, subUrl, processMsgHandler) {
      var sock = new SockJS(sockUrl);
      var stomp = Stomp.over(sock);
      stomp.heartbeat.outgoing = 5000;
      stomp.heartbeat.incoming = 5000;

      stomp.connect(
        {},
        () => {
          console.log("连接成功");
          stomp.subscribe(subUrl, processMsgHandler);
        },
        () => {
          console.log("连接失败");
        }
      );
      return stomp;
    }
  },
  created() {
    let curUser = getCurrentUser();
    this.$request(`/api/user/${curUser.id}/config`).then(res => {
      let copytemplate = res.data.copytemplate;
      if (copytemplate) {
        localStorage.setItem("copytemplate", copytemplate);
      } else {
        localStorage.removeItem("copytemplate");
      }
    });

    this.$request("/api/sys/resource/systemsetting/query?name=notifyMsg").then(
      res => {
        if (res.data && res.data.setting) {
          this.notifyMsg = res.data.setting;
        }
      }
    );

    if (this.$route.name == "Home") {
      this.$router.push({ name: "Monitor" });
    }
    let token = sessionStorage.getItem("token");
    if (token) {
      if (!this.stompClient) {
        let sockUrl =
          this.$request.baseUrl + "/socket?token=" + token.split(" ")[1];
        let subUrl = "/user/queue/notify";
        this.stompClient = this.reconnectWebsock(
          sockUrl,
          subUrl,
          this.receive_socket_msg
        );
      }
    }
  }
};
</script>

<style lang="scss">
#home {
  .views {
    width: 100%;
    height: calc(100vh - 60px);
    // overflow: auto;
  }
}
.crawldialog {
  .el-row {
    border-bottom: 1px solid gray;
  }
}
</style>
