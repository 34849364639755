<template>
  <div style="margin: 5% 10%; border-radius: 4px">
    <!-- 标题 -->

    <header class="headers">智能AI聊天对话</header>

    <!-- 内容 -->
    <hgroup class="hgroups" ref="hgroups">
      <div v-for="(item, index) in messages" v-bind:key="index">
        <div v-if="item.role == 'user'" class="user">
          <div class="textstyle" v-text="item.content"></div>
          <el-avatar style="margin-left: 10px" :src="user"></el-avatar>
        </div>

        <div v-if="item.role == 'assistant'" class="system">
          <el-avatar style="margin-right: 10px" :src="gpt"></el-avatar>
          <div class="textstyle" v-text="item.content"></div>
        </div>
      </div>
    </hgroup>

    <!-- 底部 -->

    <footer class="footers">
      <el-input
        type="textarea"
        style="border: 1px solid gray; font-size: 30px"
        :rows="2"
        class="ipt"
        :placeholder="canSend ? '请输入内容' : '当前不可发送'"
        v-model="textarea"
        :disabled="!canSend"
        @keyup.enter.native="sending"
      ></el-input>

      <el-button type="success" :disabled="!canSend" @click="sending"
        >发送按钮</el-button
      >
    </footer>
  </div>
</template>

<script>
import gpt from "@/assets/images/gpt.png";
import user from "@/assets/images/header_logo.png";

export default {
  data() {
    return {
      textarea: "", //用户输入的内容
      messages: [],
      gpt: gpt,
      user: user,
      canSend: true
    };
  },

  props: ["aimsg"],

  watch: {
    aimsg: function(val) {
      try {
        let aiJon = JSON.parse(val);
        if (aiJon.choices[0].delta.content) {
          let requestid = aiJon.requestid;
          this.messages.filter(item => {
            if (item.requestid == requestid) {
              item.content += aiJon.choices[0].delta.content;
            }
          });
        }
        if (aiJon.choices[0].finish_reason == "stop") {
          this.canSend = true;
        }
        this.handleScrollBottom();
      } catch {
        console.log("error parse ai msg");
      }
    }
  },

  methods: {
    sending() {
      if (this.textarea) {
        let msgitem = { role: "user", content: this.textarea };
        this.textarea = null;
        this.messages.push(msgitem);
        this.handleScrollBottom();
        let requestid = Date.now();

        let postmessage = new Array();

        this.messages.forEach(item => {
          postmessage.push({ role: item.role, content: item.content });
        });
        this.canSend = false;
        this.$request
          .post("/api/sys/resource/aistream", {
            messages: JSON.stringify(postmessage),
            requestid: requestid
          })
          .then(resp => {
            if (resp.data) {
              this.$message.error(resp.data);
            }
          });
        this.messages.push({
          role: "assistant",
          content: "",
          requestid: requestid
        });
      } else {
        this.$message.error("错了哦，消息不可为空"); //验证输入内容为空时 消息提示
      }
    },
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.hgroups;
        scrollElem.scrollTo({
          top: scrollElem.scrollHeight,
          behavior: "smooth"
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .headers {
  /*标题设置*/

  height: 80px; /*高*/

  background-color: aquamarine; /*背景色*/

  line-height: 100px; /*行高,控制上下居中*/

  text-align: center; /*控制左右居中*/
}

::v-deep .hgroups {
  /* 内容区样式*/

  height: 600px;

  background-color: azure;

  overflow: auto; /*聊天内容过多时自动添加滚动条*/
}

::v-deep .footers {
  /* 底部区样式*/

  display: flex; /* 控制底部框框和按钮在一排 横着显示的*/
}

::v-deep .ipt {
  margin-right: 20px; /* 底部区框框和按钮中间的间隙*/
}

::v-deep .user {
  /* 用户说话样式*/

  float: right; /* 用户说话自动靠右*/

  clear: both; /* 用户说话左边不能有东西*/

  display: flex; /* 头像和说话并排显示*/

  align-items: center; /* 头像在说话高度中间对齐*/

  margin: 10px;
}

::v-deep .textstyle {
  /* 气泡框绿色背景样式*/

  padding: 10px;

  background-color: rgb(128, 210, 98);

  border-radius: 10px;

  max-width: 500px;

  word-wrap: normal; /* 说话太长自动换行*/

  line-height: 24px;
}

::v-deep .system {
  /* 系统说话样式*/

  float: left;

  clear: both;

  display: flex;

  align-items: center;

  margin: 10px;
}
</style>
