<template>
  <div class="authorMonitor">
    <div class="left">
      <div class="left-top">
        <el-button @click="updateAuthor()">添加</el-button>
      </div>
      <el-tabs :tab-position="'left'" class="tabs" @tab-click="changeTag">
        <el-tab-pane v-for="(key, index) in tags" :key="index" :label="key">
          <div
            v-for="(item, index) in showAuthors"
            :key="index"
            class="pannel-content"
          >
            <span
              class="authorItem"
              :style="{ color: item.isCheck ? '#409eff' : '' }"
              :title="
                item.remark == null || item.remark == ''
                  ? item.name
                  : item.remark
              "
              style="cursor:pointer;"
              @click="showAuthorDocs(item)"
              v-text="showAuthorName(item)"
            ></span>
            <button @click="updateAuthor(item)">修改</button>
            <button @click="delAuthor(item)">删除</button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="right">
      <div class="right-header">
        <el-radio-group
          size="mini"
          v-model="timeType"
          style="margin-right:100px;"
          @change="changeTimeType"
        >
          <el-radio-button label="今天"></el-radio-button>
          <el-radio-button label="24小时"></el-radio-button>
          <el-radio-button label="三天内"></el-radio-button>
          <el-radio-button label="七天内"></el-radio-button>
          <el-radio-button label="30天内"></el-radio-button>
          <el-radio-button label="自定义"></el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="mini"
          v-if="timeType == '自定义'"
          v-model="rangTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          @change="requestData"
        >
        </el-date-picker>

        译文信息：<el-radio-group
          size="small"
          v-model="translate"
          @change="requestData"
        >
          <el-radio-button label="true">显示</el-radio-button>
          <el-radio-button label="false">不显示</el-radio-button>
        </el-radio-group>

        <el-button style="float:right;" @click="exportExcel">导出</el-button>
        <div style="float:right;margin-right:30px;">
          <el-input
            placeholder="请输入搜索内容"
            v-model="searchKey"
            @change="requestData"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>

      <el-table
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :key="timeStamp"
      >
        <el-table-column type="selection" min-width="3"> </el-table-column>
        <el-table-column type="index" min-width="2"> </el-table-column>
        <el-table-column prop="title" label="标题" min-width="65">
          <template slot-scope="scope">
            <p
              style="cursor:pointer;font-weight:700"
              v-html="subTitle(scope.row.title)"
              @click="goDetail(scope.row.id)"
            ></p>
            <el-divider v-if="translate == 'true'" content-position="left"
              >译文:
            </el-divider>
            <p
              v-if="translate == 'true'"
              v-html="subTitle(scope.row.transTitle)"
            ></p>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" min-width="12">
        </el-table-column>
        <el-table-column prop="source" label="来源" min-width="8">
          <template slot-scope="scope">
            <el-link
              :href="scope.row.url"
              target="_blank"
              v-text="scope.row.source"
            ></el-link>
          </template>
        </el-table-column>
        <el-table-column prop="author" label="博主" min-width="10">
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="pageBean.number"
        :page-size.sync="pageBean.size"
        :page-sizes="[10, 30, 50]"
        layout="total,sizes,prev, pager, next"
        :total.sync="pageBean.totalElements"
      >
      </el-pagination>
      <el-dialog
        width="30%"
        :title="form.id != null ? '修改' : '添加'"
        :visible.sync="dialogFormVisible"
      >
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="文主名称：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="备注名称：">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>

          <el-form-item label="文主链接：">
            <el-input v-model="form.url"></el-input>
          </el-form-item>

          <el-form-item label="文主平台：" style="text-align:left;">
            <el-select placeholder="请选择所在平台" v-model="form.se.id">
              <el-option
                v-for="(item, index) in notCommonSe"
                :key="index"
                :label="item.name"
                :value="item.id"
                :disabled="!item.enable"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文主标签：" style="text-align:left;">
            <el-select
              filterable
              allow-create
              v-model="form.tag"
              placeholder="请选择或输入标签"
            >
              <el-option
                :key="index"
                v-for="(value, index) in showTags()"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预警词：">
            <el-input v-model="form.keywords"></el-input>
          </el-form-item>

          <el-form-item label="预警：" style="text-align:left;">
            <el-switch v-model="form.alarm"></el-switch>
          </el-form-item>

          <el-form-item label="状态：" style="text-align:left;">
            <el-switch v-model="form.enable"></el-switch>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="
                dialogFormVisible = false;
                onSubmit();
              "
              v-text="form.id != null ? '修改' : '添加'"
            ></el-button>
            <el-button @click="dialogFormVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
import { highStr } from "../utils/api";
export default {
  data() {
    return {
      timeStamp: new Date().getTime(),
      notCommonSe: [],
      tags: [],
      allAuthors: [],
      showAuthors: [],
      tableData: [],
      multipleSelection: [],
      pageBean: { number: 1, size: 10, totalElements: 0 },
      cqAuthors: [],
      rangTime: "",
      timeType: "七天内",
      form: { se: {} },
      dialogFormVisible: false,
      translate: false,
      searchKey: ""
    };
  },
  methods: {
    showAuthorName(item) {
      let descName =
        item.remark == null || item.remark == "" ? item.name : item.remark;

      if (descName.length > 5) {
        descName = descName.substring(0, 5);
      }

      if (item.alarm) {
        descName += " 🔔";
      }

      return descName;
    },
    showTags() {
      return this.tags.filter(tag => {
        return tag != "全部" && tag != "未分类";
      });
    },
    highStr(str) {
      return highStr(str);
    },
    changeTag(tab) {
      this.checkTags(tab.label);
    },
    checkTags(tag) {
      if (tag == "全部") {
        this.cqAuthors = this.allAuthors;
      } else if (tag == "未分类") {
        this.cqAuthors = this.allAuthors.filter(item => {
          return !item.tag;
        });
      } else {
        this.cqAuthors = this.allAuthors.filter(item => {
          return item.tag == tag;
        });
      }
      this.showAuthors = this.cqAuthors;
      this.showAuthors.forEach(item => {
        delete item.isCheck;
      });
      this.requestData();
    },
    updateAuthor(author) {
      if (author) {
        this.form = { ...author };
      } else {
        this.form = { se: { id: null } };
      }
      //特殊搜索任务 获取非公共采集引擎
      this.$request
        .get("/api/crawl/se", {
          page: 1,
          size: 100,
          common: false,
          keyword: "文主"
        })
        .then(res => {
          this.notCommonSe = res.data.content;
          this.notCommonSe.map(se => {
            se.name = se.name.replace("文主-", "");
          });
        });

      this.dialogFormVisible = true;
    },
    onSubmit() {
      this.$request.post("/api/crawl/authorMonitor", this.form).then(() => {
        this.$message.success("修改成功");
        this.updateShow = false;
        this.resetNodeTree();
      });
    },
    delAuthor(author) {
      this.$confirm(
        "此操作将删除“" + author.name + "”文主, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$request
          .delete("/api/crawl/authorMonitor/" + author.id)
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.resetNodeTree();
          });
      });
    },
    changeTimeType() {
      if (this.timeType != "自定义") {
        this.requestData();
      }
    },
    goDetail(id) {
      let routeData = this.$router.resolve(`/detail?id=${id}`);
      window.open(routeData.href, "_blank");
    },
    subTitle(title) {
      if (title) {
        if (title.length > 200) {
          title = title.substring(0, 200) + "...";
        }
        return highStr(title);
      }
      return null;
    },
    showAuthorDocs(author) {
      this.cqAuthors = [author];
      this.showAuthors.forEach(item => {
        delete item.isCheck;
        if (author.id == item.id) {
          item.isCheck = true;
        }
      });

      this.requestData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    resetNodeTree() {
      this.$request
        .get("/api/crawl/authorMonitor", { page: 1, size: 1000 })
        .then(res => {
          this.allAuthors = res.data.content;
          this.tags = ["全部"];
          let hasNoTagAuthor = false;
          res.data.content.map(author => {
            if (!author.tag) {
              if (!hasNoTagAuthor) {
                hasNoTagAuthor = true;
              }
            } else {
              if (!this.tags.includes(author.tag)) {
                this.tags.push(author.tag);
              }
            }
          });
          if (hasNoTagAuthor) {
            this.tags.push("未分类");
          }
          console.log(this.tags);
          //默认选中第一个标签查询
          this.checkTags("全部");
        });
    },
    exportExcel() {
      //根据选中或者条件导出数据
      let checkIds = [];
      let requestParams = this.getRequestParams();
      requestParams.type = "excel";
      if (this.multipleSelection.length > 0) {
        //根据选中导出数据
        this.multipleSelection.forEach(doc => {
          checkIds.push(doc.id);
        });
        this.exportExcelByParams({
          rule: "id:" + checkIds.join(" "),
          startTime: requestParams.startTime,
          endTime: requestParams.endTime,
          type: requestParams.type
        });
      } else {
        //根据条件导出数据
        this.$confirm("此操作将导出所有查询的数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.exportExcelByParams(requestParams);
        });
      }
    },
    exportExcelByParams(params) {
      this.$request
        .request({
          url: "/api/doc/exportExcel",
          method: "post",
          responseType: "text",
          data: Qs.stringify(params)
        })
        .then(() => {
          this.$message.info("后台文件生成中...!");
        });
    },
    getRequestParams() {
      //查询选中的作者
      if (this.cqAuthors.length == 0) return;
      let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      let filterRule = "removeUsers:" + currentUser.id;
      let queryRule = new Array();
      this.cqAuthors.map(author => {
        let aRule = `author:"${author.name}"&&source:${author.se.name.replace(
          "文主-",
          ""
        )}`;
        if (this.searchKey != "") {
          aRule += "\ntitle+content:" + this.searchKey;
        }
        queryRule.push(aRule);
      });

      let startTime = new Date();
      let endTime = new Date();

      switch (this.timeType) {
        case "今天":
          startTime.setHours(0, 0, 0);
          break;
        case "24小时":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 1);
          break;
        case "三天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 3);
          break;
        case "七天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 7);
          break;
        case "30天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 30);
          break;
        case "自定义":
          startTime = this.rangTime[0];
          endTime = this.rangTime[1];
          if (!startTime || !endTime) {
            //未选择时间 默认选中今天
            this.timeType = "今天";
            startTime.setHours(0, 0, 0);
          }
          break;
      }

      return {
        filterRule: filterRule,
        rule: queryRule.join(","),
        page: this.pageBean.number,
        size: this.pageBean.size,
        startTime: startTime.format(),
        endTime: endTime.format()
      };
    },

    requestData() {
      this.$request.post("/api/doc", this.getRequestParams()).then(res => {
        this.tableData = [];
        res.data.number = res.data.number + 1;
        this.pageBean = res.data;
        res.data.content.map(doc => {
          let item = {
            id: doc.id,
            title: doc.title,
            time: doc.time,
            source: doc.source,
            author: doc.author,
            url: doc.url
          };

          if (this.translate == "true") {
            this.$request
              .post("/api/sys/resource/translate", {
                docId: doc.id,
                transCnt: false
              })
              .then(res => {
                item.transTitle = res.data[0];
                this.timeStamp = new Date().getTime();
              });
          }

          this.tableData.push(item);
        });
      });
    }
  },
  created() {
    this.resetNodeTree();
  }
};
</script>
<style>
.authorMonitor .el-tabs__item {
  font-size: 20px;
  height: 60px;
  line-height: 60px;
}
</style>
<style lang="scss" scope>
.authorMonitor {
  height: 100%;
  background: #fff;
  display: flex;
  .left {
    padding: 30px;
    .el-tabs {
      width: 350px;
    }
    .pannel-content {
      width: 250px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      text-align: left;
      button {
        // margin-left: 10px;
        border: none;
        outline: none;
        color: #5090f1;
        background: none;
        cursor: pointer;
      }
    }
    .left-top {
      margin-bottom: 20px;
      text-align: left;
    }
  }
  .right {
    width: 100%;
    padding: 30px;
    overflow: auto;
    .right-header {
      text-align: left;
      .el-range-editor.el-input__inner {
        margin-left: 10px;
      }
    }
    .el-pagination {
      margin-top: 30px;
    }
  }

  .authorItem:hover {
    color: #409eff;
  }
}
.el-tabs__content {
  overflow-y: auto !important;
  height: 45em;
}
</style>
