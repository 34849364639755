<template>
  <div :key="timeStamp" class="list-box" v-loading="fullscreenLoading">
    <el-divider v-if="newDocCount > 0"
      ><el-link @click="flushDoc" type="primary"
        ><span v-text="newDocCount"></span>条新信息，点击查看</el-link
      ></el-divider
    >
    <div
      class="list-item"
      v-for="(item, index) in dataList.content"
      :key="index"
      :class="{ isread: false }"
    >
      <div class="list-item-top">
        <div class="tag-box">
          <input :value="item.id" v-model="checkIds" type="checkbox" />
          <span class="num" v-text="index + 1"></span>
          <span
            v-if="
              item.alarmUsers != null &&
                item.alarmUsers.indexOf(currentUserId) != -1
            "
            class="warning-icon"
            >预警</span
          >
          <span v-if="2 == item.risis" class="warning-icon">敏</span>
        </div>
        <span
          class="list-title"
          v-html="highStr(item.title)"
          @click="goDetail(item.id)"
        ></span>
        <span></span>
      </div>
      <div class="list-item-bottom">
        <div
          class="list-content"
          v-html="highStr(item.content)"
          @click="goDetail(item.id)"
        ></div>
        <div class="content-bottom">
          <div class="list-info">
            <span class="time" v-text="item.time"></span>|
            <span
              class="time"
              v-text="item.spiderTime"
              style="margin-left:3px;"
            ></span>
            <span class="username">
              <i class="el-icon-user user-icon" v-text="item.author"></i>
            </span>
            <a
              class="source"
              v-text="item.source"
              :href="item.url"
              target="_blank"
            ></a>
          </div>
          <div class="list-icon">
            <div class="icon-box" @click="collectDocDialog(item)">
              <i class="el-icon-star-off"></i>
              <div>收藏</div>
            </div>

            <div
              v-if="isSupportPlatform(item)"
              class="icon-box"
              @click="authorMointorDialog(item)"
            >
              <i class="el-icon-magic-stick"></i>
              <div>追踪</div>
            </div>

            <div
              v-if="
                item.alarmUsers == null ||
                  item.alarmUsers.indexOf(currentUserId) == -1
              "
              class="icon-box"
              @click="alarmDoc(item)"
            >
              <i class="el-icon-bell"></i>
              <div>预警</div>
            </div>
            <!-- <div class="icon-box" @click="copyDetail(item)">
              <i class="el-icon-magic-stick"></i>
              <div>一键追踪</div>
            </div> -->
            <div class="icon-box" @click="copyDetail(item)">
              <i class="el-icon-document-copy"></i>
              <div>复制</div>
            </div>
            <div class="icon-box" @click="openUrl(item)">
              <i class="el-icon-link"></i>
              <div>链接</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showTranslate">
        <el-divider content-position="left">译文: </el-divider>
        <div class="list-item-top">
          <span
            class="list-title"
            style="font-weight:400"
            v-html="highStr(item.transTitle)"
          ></span>
          <span></span>
        </div>

        <div class="list-item-bottom">
          <div class="list-content" v-html="highStr(item.transCnt)"></div>
        </div>
      </div>
    </div>

    <el-dialog width="20%" title="标签选择" :visible.sync="dialogFormVisible">
      <el-select
        filterable
        allow-create
        v-model="selectTag"
        placeholder="请选择或输入标签"
      >
        <el-option
          :key="index"
          v-for="(value, key, index) in tags"
          :label="value"
          :value="value"
        ></el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            isCollectDialog ? collectDoc() : authorMointer();
            dialogFormVisible = false;
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { highStr, getHotWords, copyMessage } from "../utils/api.js";

export default {
  data() {
    return {
      timeStamp: new Date().getTime(),
      selectId: null,
      selectDoc: null,
      selectTag: null,
      tags: [],
      authorTags: [],
      dialogFormVisible: false,
      fullscreenLoading: false,
      defaultParams: {
        duplicate: true,
        startTime: ""
      },
      checkIds: [],
      currentParams: {},
      dataList: [],
      currentUserId: null,
      showTranslate: false,
      hotWords: [],
      defHotWords: [],
      notCommonSe: [],
      isCollectDialog: true,
      newDocCount: 0,
      lastRequsetTime: null
    };
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    checkAll: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.currentUserId = JSON.parse(sessionStorage.getItem("currentUser")).id;

    this.defHotWords = await getHotWords();
    //特殊搜索任务 获取非公共采集引擎
    this.$request
      .get("/api/crawl/se", {
        page: 1,
        size: 100,
        common: false,
        keyword: "文主"
      })
      .then(res => {
        this.notCommonSe = res.data.content;
        this.notCommonSe.map(se => {
          se.name = se.name.replace("文主-", "");
        });
      });

    this.$request(
      "/api/sys/resource/systemsetting/query?name=autoFlushDocCount"
    ).then(res => {
      if (res.data && res.data.setting) {
        let autoFlushUsers = res.data.setting.split(",");
        if (autoFlushUsers.indexOf(this.currentUserId.toString()) > -1) {
          console.log("该用户为自动刷新数据量的用户");
          setInterval(() => {
            this.getNewDocCount();
          }, 60000);
        }
      }
    });
  },
  methods: {
    getCheckIds() {
      //获取选中的数据id
      return this.checkIds;
    },
    getFullQueryParam() {
      return this.currentParams;
    },
    highStr(str) {
      return highStr(str, this.hotWords);
    },

    getRequestData() {
      let requestData = {
        ...this.defaultParams,
        ...this.currentParams,
        ...this.params
      };

      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      let filterRule = "removeUsers:" + this.currentUser.id;
      //补充删除条件
      if (requestData.filterRule) {
        //存在条件
        let hasRemoveRule = requestData.filterRule.indexOf("removeUsers") > -1;
        filterRule =
          requestData.filterRule + (hasRemoveRule ? "" : "," + filterRule);
      }
      requestData.filterRule = filterRule;

      this.showTranslate = "true" == requestData.translate;
      delete requestData.translate;
      return requestData;
    },
    requestMessageList() {
      this.newDocCount = 0;
      this.lastRequsetTime = new Date();
      this.fullscreenLoading = true;
      let requestData = this.getRequestData();
      this.currentParams = requestData;

      //设置高亮词
      this.hotWords = new Array();

      var kuohaoPatt = /\([\s\S]+?\)/g;
      let highArrayWord = [];
      let rule = this.currentParams.rule;
      var res = rule.match(kuohaoPatt);
      if (res) {
        res.forEach(rr => {
          rule = rule.replace(rr, "");
          rr = rr.replaceAll(/[(|)]/g, "");
          highArrayWord.push(rr);
        });
      }

      var patt = /[ |+|,|\n]/;
      rule.split(patt).forEach(dd => {
        highArrayWord.push(dd);
      });

      highArrayWord.forEach(highword => {
        if (highword != "") {
          this.hotWords[highword] = "red";
        }
      });
      //去除默认的高亮词
      let hasArray = new Array();
      for (let dwk in this.defHotWords) {
        for (let wk in this.hotWords) {
          if (wk.indexOf(dwk) != -1) {
            hasArray.push(dwk);
          }
        }
      }

      let tmpWords = { ...this.defHotWords };
      hasArray.forEach(dwk => {
        delete tmpWords[dwk];
      });

      this.hotWords = { ...this.hotWords, ...tmpWords };

      //请求数据
      this.$request
        .post("/api/doc", requestData)
        .then(data => {
          this.dataList = data.data;
          this.checkIds = [];
          this.$emit("setDocCount", this.dataList.totalElements);
          this.fullscreenLoading = false;
          if (this.showTranslate) {
            this.dataList.content.forEach(doc => {
              this.transdoc(doc);
            });
          }
        })
        .catch(() => (this.fullscreenLoading = false));
    },
    transdoc(doc) {
      // doc.transTitle = doc.title;
      // doc.transCnt = doc.content;
      this.$request
        .post("/api/sys/resource/translate", { docId: doc.id, transCnt: false })
        .then(res => {
          doc.transTitle = res.data[0];
          doc.transCnt = res.data[1];
          this.timeStamp = new Date().getTime();
        });
    },
    goDetail(id) {
      let routeData = this.$router.resolve(`/detail?id=${id}`);
      window.open(routeData.href, "_blank");
      // this.$router.push({ name: "Detail", params: data });
    },
    openUrl(doc) {
      //打开文章链接
      window.open(doc.url);
    },
    copyDetail(doc) {
      copyMessage(doc, this);
    },
    alarmDoc(doc) {
      //批量收藏数据
      this.$request
        .post("/api/doc/alarm", {
          action: "add",
          ids: doc.id
        })
        .then(() => {
          doc.alarmUsers = [this.currentUserId];
        });
    },

    collectDoc() {
      //批量收藏数据
      this.$request
        .post("/api/doc/collect", {
          action: "collect",
          ids: this.selectId,
          tag: this.selectTag
        })
        .then(() => {
          this.$message.success("收藏成功!");
        });
    },
    isSupportPlatform(doc) {
      let supportplatform = ["推特"];
      if (supportplatform.indexOf(doc.source) != -1) {
        return true;
      }
      return false;
    },
    authorMointer() {
      let doc = this.selectDoc;
      let source = doc.source;
      if (source == "推特") {
        let se = this.notCommonSe.filter(se => se.name == "推特")[0];
        console.log(se);
        let authorUrl = doc.url.substring(0, doc.url.indexOf("/status"));
        let form = {
          name: doc.author,
          url: authorUrl,
          se: { id: se.id },
          tag: this.selectTag,
          enable: true,
          alarm: true
        };

        this.$request.post("/api/crawl/authorMonitor", form).then(() => {
          this.$message.success("用户追踪成功");
        });
      }
    },
    collectDocDialog(doc) {
      this.selectId = doc.id;
      this.$request.get("/api/doc/collect", { action: "tags" }).then(res => {
        this.selectTag = null;
        this.tags = res.data;
        this.dialogFormVisible = true;
        this.isCollectDialog = true;
      });
    },
    authorMointorDialog(doc) {
      this.selectDoc = doc;
      this.$request.get("/api/crawl/authorMonitor/tags").then(res => {
        this.selectTag = null;
        this.tags = res.data;
        this.dialogFormVisible = true;
        this.isCollectDialog = false;
      });
    },
    flushDoc() {
      this.lastRequsetTime = null;
      this.params.endTime = new Date().format();
      this.requestMessageList();
    },
    getNewDocCount() {
      if (this.lastRequsetTime != null) {
        console.log("自动刷新主题新增数据量。。。");
        let requestData = this.getRequestData();
        requestData.startTime = this.lastRequsetTime.format();
        requestData.endTime = new Date().format();
        this.$request.post("/api/doc/docCount", requestData).then(data => {
          this.newDocCount = data.data;
        });
      }
    }
  },
  watch: {
    params() {
      this.requestMessageList();
    },
    checkAll() {
      if (this.checkAll) {
        this.dataList.content.forEach(element => {
          this.checkIds.push(element.id);
        });
      } else {
        this.checkIds = [];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.list-box {
  padding: 0px 10px 10px 10px;
  .list-item {
    &.isread {
      // .list-item-top {
      //   .tag-box {
      //     .num {
      //       color: #999999;
      //     }
      //   }
      //   .list-title {
      //     color: #999999;
      //   }
      // }
      // .list-item-bottom {
      .list-content,
      .time,
      .username,
      .num,
      .list-title {
        color: #999999 !important;
      }
      // .list-info {
      //   .time,
      //   .username {
      //     color: #999999;
      //   }
      // }
      // }
    }
    padding: 14px 10px;
    .list-item-top {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .tag-box {
        display: flex;
        align-items: center;
        input {
          margin: 0;
        }
        .num {
          font-size: 16px;
          color: #222222;
          margin: 0 4px;
          font-weight: 550;
        }
        .warning-icon {
          display: inline-block;
          white-space: nowrap;
          padding: 1px 4px;
          margin-right: 4px;
          border-radius: 2px;
          font-size: 14px;
          color: #fff;
          background: #e65441;
        }
      }
      .list-title {
        cursor: pointer;
        color: #222222;
        font-size: 16px;
        line-height: 1.6;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .list-item-bottom {
      text-align: left;
      .list-content {
        cursor: pointer;
        color: #4e4e4e;
        line-height: 1.5;
        font-size: 14px;
        margin-bottom: 8;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 3;
      }
      .content-bottom {
        display: flex;
        align-items: center;
        .list-info {
          flex: 1;
          height: 20px;
          line-height: 20px;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            font-size: 12px;
            margin-right: 10px;
            // vertical-align: baseline;
          }
          .time {
            color: #222222;
          }
          .user-icon {
            margin-right: 4px;
          }
          .username,
          .source {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            text-decoration: none;
          }
          .source {
            color: #5090f1;
          }
        }
        .list-icon {
          flex: 1;
          text-align: right;
          .icon-box {
            display: inline-block;
            position: relative;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            &:hover {
              color: #5090f1;
              div {
                display: block;
              }
            }
            div {
              display: none;
              // padding: 10px;
              width: 60px;
              height: 24px;
              line-height: 24px;
              color: #333;
              font-size: 12px;
              position: absolute;
              top: -120%;
              left: -40%;
              border: 1px #5090f1 solid;
              border-radius: 5px;
              background: #fff;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-bottom: 8px solid rgba(0, 0, 0, 0);
                border-top: 8px solid #5090f1;
                border-left: 8px solid rgba(0, 0, 0, 0);
                border-right: 8px solid rgba(0, 0, 0, 0);
                background-color: none;
                position: absolute;
                top: 100%;
                left: 35%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
