var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fullscreenLoading),expression:"fullscreenLoading"}],key:_vm.timeStamp,staticClass:"list-box"},[(_vm.newDocCount > 0)?_c('el-divider',[_c('el-link',{attrs:{"type":"primary"},on:{"click":_vm.flushDoc}},[_c('span',{domProps:{"textContent":_vm._s(_vm.newDocCount)}}),_vm._v("条新信息，点击查看")])],1):_vm._e(),_vm._l((_vm.dataList.content),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:{ isread: false }},[_c('div',{staticClass:"list-item-top"},[_c('div',{staticClass:"tag-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkIds),expression:"checkIds"}],attrs:{"type":"checkbox"},domProps:{"value":item.id,"checked":Array.isArray(_vm.checkIds)?_vm._i(_vm.checkIds,item.id)>-1:(_vm.checkIds)},on:{"change":function($event){var $$a=_vm.checkIds,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkIds=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkIds=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkIds=$$c}}}}),_c('span',{staticClass:"num",domProps:{"textContent":_vm._s(index + 1)}}),(
            item.alarmUsers != null &&
              item.alarmUsers.indexOf(_vm.currentUserId) != -1
          )?_c('span',{staticClass:"warning-icon"},[_vm._v("预警")]):_vm._e(),(2 == item.risis)?_c('span',{staticClass:"warning-icon"},[_vm._v("敏")]):_vm._e()]),_c('span',{staticClass:"list-title",domProps:{"innerHTML":_vm._s(_vm.highStr(item.title))},on:{"click":function($event){return _vm.goDetail(item.id)}}}),_c('span')]),_c('div',{staticClass:"list-item-bottom"},[_c('div',{staticClass:"list-content",domProps:{"innerHTML":_vm._s(_vm.highStr(item.content))},on:{"click":function($event){return _vm.goDetail(item.id)}}}),_c('div',{staticClass:"content-bottom"},[_c('div',{staticClass:"list-info"},[_c('span',{staticClass:"time",domProps:{"textContent":_vm._s(item.time)}}),_vm._v("| "),_c('span',{staticClass:"time",staticStyle:{"margin-left":"3px"},domProps:{"textContent":_vm._s(item.spiderTime)}}),_c('span',{staticClass:"username"},[_c('i',{staticClass:"el-icon-user user-icon",domProps:{"textContent":_vm._s(item.author)}})]),_c('a',{staticClass:"source",attrs:{"href":item.url,"target":"_blank"},domProps:{"textContent":_vm._s(item.source)}})]),_c('div',{staticClass:"list-icon"},[_c('div',{staticClass:"icon-box",on:{"click":function($event){return _vm.collectDocDialog(item)}}},[_c('i',{staticClass:"el-icon-star-off"}),_c('div',[_vm._v("收藏")])]),(_vm.isSupportPlatform(item))?_c('div',{staticClass:"icon-box",on:{"click":function($event){return _vm.authorMointorDialog(item)}}},[_c('i',{staticClass:"el-icon-magic-stick"}),_c('div',[_vm._v("追踪")])]):_vm._e(),(
              item.alarmUsers == null ||
                item.alarmUsers.indexOf(_vm.currentUserId) == -1
            )?_c('div',{staticClass:"icon-box",on:{"click":function($event){return _vm.alarmDoc(item)}}},[_c('i',{staticClass:"el-icon-bell"}),_c('div',[_vm._v("预警")])]):_vm._e(),_c('div',{staticClass:"icon-box",on:{"click":function($event){return _vm.copyDetail(item)}}},[_c('i',{staticClass:"el-icon-document-copy"}),_c('div',[_vm._v("复制")])]),_c('div',{staticClass:"icon-box",on:{"click":function($event){return _vm.openUrl(item)}}},[_c('i',{staticClass:"el-icon-link"}),_c('div',[_vm._v("链接")])])])])]),(_vm.showTranslate)?_c('div',[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("译文: ")]),_c('div',{staticClass:"list-item-top"},[_c('span',{staticClass:"list-title",staticStyle:{"font-weight":"400"},domProps:{"innerHTML":_vm._s(_vm.highStr(item.transTitle))}}),_c('span')]),_c('div',{staticClass:"list-item-bottom"},[_c('div',{staticClass:"list-content",domProps:{"innerHTML":_vm._s(_vm.highStr(item.transCnt))}})])],1):_vm._e()])}),_c('el-dialog',{attrs:{"width":"20%","title":"标签选择","visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-select',{attrs:{"filterable":"","allow-create":"","placeholder":"请选择或输入标签"},model:{value:(_vm.selectTag),callback:function ($$v) {_vm.selectTag=$$v},expression:"selectTag"}},_vm._l((_vm.tags),function(value,key,index){return _c('el-option',{key:index,attrs:{"label":value,"value":value}})}),1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.isCollectDialog ? _vm.collectDoc() : _vm.authorMointer();
          _vm.dialogFormVisible = false;}}},[_vm._v("确 定")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }