<template>
  <div class="net-main">
    <div class="logo"><img src="../assets/images/netsearch.png" alt="" /></div>
    <div class="search-input">
      <el-input
        placeholder="请输入内容"
        v-model="keyword"
        class="input-with-select"
        @keyup.enter.native="searchKey"
      >
        <el-select v-model="field" slot="prepend" placeholder="请选择">
          <el-option label="全文" value="title+content"></el-option>
          <el-option label="链接" value="url"></el-option>
          <el-option label="来源" value="source"></el-option>
          <el-option label="作者" value="author"></el-option>
        </el-select>
        <el-button
          class="search-btn"
          slot="append"
          icon="el-icon-search"
          @click="searchKey"
        ></el-button>
      </el-input>
    </div>

    <div class="result" v-show="showResult">
      <div class="result-left" ref="scrollbox">
        <div class="result-count">
          为您找到相关结果<span v-text="docCount"></span>条
        </div>
        <MessageList :params="params" @setDocCount="setDocCount"></MessageList>

        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :hide-on-single-page="true"
          :page-sizes="[20, 30, 50, 100]"
          layout="total,sizes,prev, pager, next"
          :total="docCount"
        >
        </el-pagination>
      </div>
      <div class="result-right">
        <h4 title="">筛选</h4>
        <div class="filter-item">
          <div class="title-item">时间范围</div>
          <div
            class="btn-item"
            v-for="(item, index) in timeTypes"
            :key="index"
            :label="item"
            v-text="item"
            :class="item == timeType ? 'hover' : ''"
            @click="timeType = item"
          ></div>

          <div class="customTime" v-if="timeType == '自定义'">
            <el-date-picker
              size="mini"
              v-model="rangeStartTime"
              type="datetime"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span class="timeSplit">~</span>
            <el-date-picker
              size="mini"
              v-model="rangeEndTime"
              type="datetime"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="filter-item">
          <div class="title-item">信息类型</div>
          <div
            class="btn-item"
            v-for="(item, index) in docTypes"
            :key="index"
            :label="item"
            v-text="item"
            :class="selectType.indexOf(item) != -1 ? 'hover' : ''"
            @click="selectDocType(item)"
          ></div>
        </div>

        <div class="filter-item">
          <div class="title-item">相似信息</div>
          <div
            class="btn-item"
            v-for="(item, index) in duplicateArr"
            :key="index"
            :label="item"
            v-text="item"
            :class="duplicate == item ? 'hover' : ''"
            @click="duplicate = item"
          ></div>
        </div>

        <div class="filter-item">
          <div class="title-item">译文信息</div>
          <div
            class="btn-item"
            v-for="(item, index) in transArr"
            :key="index"
            :label="item"
            v-text="item"
            :class="trans == item ? 'hover' : ''"
            @click="trans = item"
          ></div>
        </div>

        <el-button class="button-wary" type="primary" @click="searchKey">
          搜索
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import MessageList from "./MessageList.vue";

export default {
  components: {
    MessageList
  },
  data() {
    return {
      field: "title+content",
      keyword: "",
      timeTypes: ["今天", "24小时", "三天内", "七天内", "自定义"],
      timeType: "24小时",
      docTypes: ["新闻", "推特", "论坛", "网页", "视频", "电报"],
      selectType: [],
      duplicateArr: ["去重", "不去重"],
      duplicate: "不去重",
      transArr: ["显示", "不显示"],
      trans: "不显示",
      rangeStartTime: null,
      rangeEndTime: null,
      showResult: false,
      currentPage: 1,
      pageSize: 15,
      params: {},
      docCount: 0
    };
  },
  mounted() {},
  methods: {
    searchKey() {
      this.showResult = true;
      this.currentPage = 1;
      this.params = {
        duplicate: false,
        rule: this.field + ":" + this.keyword,
        page: this.currentPage
      };

      //设置时间查询范围
      let startTime = new Date();
      let nowTime = new Date();
      let endTime = null;

      switch (this.timeType) {
        case "今天":
          startTime.setHours(0, 0, 0);
          break;
        case "24小时":
          startTime.setTime(nowTime - 3600 * 1000 * 24 * 1);
          break;
        case "三天内":
          startTime.setTime(nowTime - 3600 * 1000 * 24 * 3);
          break;
        case "七天内":
          startTime.setTime(nowTime - 3600 * 1000 * 24 * 7);
          break;
        case "自定义":
          if (!this.rangeStartTime || !this.rangeEndTime) {
            return;
          }
          startTime = this.rangeStartTime;
          endTime = this.rangeEndTime;
          break;
      }
      endTime = endTime != null ? endTime.format() : new Date().format();
      startTime = startTime.format();
      this.params.startTime = startTime;
      this.params.endTime = endTime;

      //设置查询类型
      if (this.selectType.length > 0) {
        this.params.category = this.selectType.join(",");
      }

      //设置去重
      this.params.duplicate = this.duplicate == "去重" ? true : false;

      //设置翻译
      this.params.translate = this.trans == "显示" ? "true" : "false";
    },
    setDocCount(data) {
      this.docCount = data;
      this.$refs.scrollbox.scrollTop = 0;
    },
    handleCurrentChange(currentPage) {
      this.params = { page: currentPage };
    },
    handleSizeChange(pageSize) {
      this.params = { size: pageSize };
    },
    selectDocType(item) {
      if (this.selectType.indexOf(item) != -1) {
        //存在该类型 进行类型删除
        this.selectType = this.selectType.filter(ss => ss != item);
      } else {
        this.selectType.push(item);
      }
    }
  }
};
</script>
<style>
.search-input .el-input-group__append {
  background-color: #2f44ad;
  color: white;
}
</style>
<style lang="scss" scoped>
.net-main {
  height: 100%;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.logo,
.search-input {
  width: 30%;
  margin: 0 auto 0;
  text-align: center;
}
.logo {
  padding: 20px 0;
}
.search-input {
  margin-bottom: 30px;
}

.result {
  flex: 1;
  padding: 0 10%;
  display: flex;
  overflow: hidden;
}
.result-left {
  overflow: auto;
  width: 70%;
  margin: 0 50px 50px 0;
}

.customTime {
  margin-top: 8px;
  display: flex;
  padding-left: 8px;
}
.timeSplit {
  color: #ccc;
  padding: 0 5px;
  line-height: 100%;
}
.result-count span {
  color: #df564b;
  font-weight: bold;
}

.result-left::-webkit-scrollbar {
  display: none;
}
.title-item {
  display: block;
  font-size: 0.75rem;
  line-height: 18px;
  color: #666666;
  position: relative;
}
.filter-item {
  padding: 10px 0 0 10px;
}
.button-wary {
  margin: 20px 0 0 20px;
}
.btn-item {
  padding: 3px 8px;
  font-size: 0.75rem;
  border-radius: 2px;
  border: 1px solid #dadcdf;
  color: black;
  margin: 8px 0 0 7px;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}

.btn-item.hover,
.btn-item:hover {
  border-color: #5090f1;
  background-color: #5090f1;
  color: #fff;
}
</style>
