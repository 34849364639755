<template>
  <div class="messageBoard">
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="留言类型：">
        <el-select style="float:left;" v-model="checkMsgType">
          <el-option
            v-for="(item, index) in msgType"
            :key="index"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>

        <el-input
          style="width:80%;"
          type="textarea"
          :rows="6"
          :placeholder="placeholder"
          v-model="form.msg"
        ></el-input>
      </el-form-item>

      <el-form-item label="截图：">
        <label for="explore">
          <input
            id="explore"
            type="file"
            style="display: none"
            ref="fileInput"
          />选择图片
        </label>
      </el-form-item>

      <el-form-item label="联系方式：">
        <el-input
          placeholder="请输入联系方式"
          v-model="form.contact"
          class="input-with-select"
        >
          <el-select
            v-model="form.contactType"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="电话" value="电话"></el-option>
            <el-option label="QQ" value="QQ"></el-option>
            <el-option label="微信" value="微信"></el-option>
          </el-select>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交留言</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import Qs from "qs";

export default {
  data() {
    return {
      form: {
        msg: "",
        contactType: "电话",
        contact: ""
      },
      msgType: [
        { name: "新增关键词", desc: "XX主题：A B C" },
        { name: "新增站点", desc: "http://XXXX" },
        { name: "文主追踪", desc: "文主名称+文主链接" },
        { name: "特殊主题", desc: "网站+关键词" },
        { name: "建议反馈", desc: "" }
      ],
      checkMsgType: null
    };
  },
  computed: {
    placeholder: function() {
      let desc = null;
      this.msgType.forEach(msg => {
        if (msg.name == this.checkMsgType) {
          desc = msg.desc;
        }
      });
      return desc;
    }
  },
  methods: {
    onSubmit() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];
      console.log(file);
      const formData = new FormData();
      formData.append("file", file);
      // console.log(this.form.msg);
      formData.append("msg", this.form.msg);
      // console.log(formData.get("msg"));
      // if (this.form.checkMsgType) {
      //   formData.append("msg", this.checkMsgType + this.form.msg);
      // }
      // console.log(formData);
      // if (this.form.contact) {
      //   formData.append("contact", this.form.contact);
      // }
      // console.log(formData);
      // console.log(formData);

      //数据导出
      this.$request
        .request({
          url: "/api/sys/resource/msgboard",
          method: "post",
          responseType: "text",
          data: formData
        })
        .then(() => {
          this.$message.success("留言成功！我们将尽快处理该留言！");
          this.form.msg = "";
          this.form.contact = "";
          this.form.contactType = "电话";
        });

      // this.$axios_request
      //   .post("/api/sys/resource/msgboard", formData, {
      //     "Content-Type": "multipart/form-data"
      //   })
      //   .then(() => {
      //     this.$message.success("留言成功！我们将尽快处理该留言！");
      //     this.form.msg = "";
      //     this.form.contact = "";
      //     this.form.contactType = "电话";
      //   });
    }
  }
};
</script>
<style>
.el-select .el-input {
  width: 130px;
}
</style>
<style lang="scss" scoped>
.messageBoard {
  width: 40%;
  height: 100%;
  margin: 0 auto;
  padding-top: 80px;
}
</style>
