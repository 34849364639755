import { get } from "./request";

let hotWords = {};
let regex = /(<([^>]+)>)/gi;

//重置高亮词汇
async function resetHotWords() {
  hotWords = await getHotWords();
}

//获取高亮词汇
async function getHotWords() {
  let hWords = {};
  let data = await get("/api/sys/resource/wordlib", { page: 1, size: 50 });
  data.data.content.forEach(word => {
    word.words.split(" ").forEach(hot => {
      hWords[hot] = word.color;
    });
  });
  return hWords;
}

//字符串去重
function duplicateWords(str, splitStr) {
  if (!splitStr) {
    splitStr = " ";
  }
  if (str != null && str != "") {
    let strArray = str.split(splitStr);
    let dupArray = new Array();
    strArray.forEach(item => {
      if (!dupArray.includes(item)) {
        dupArray.push(item);
      }
    });
    str = dupArray.join(splitStr);
  }
  return str;
}

//高亮字符串
function highStr(str, words) {
  if (str) {
    str = str.replace(regex, "");
    let highWords = words ? words : hotWords;

    for (let high in highWords) {
      if (high != "") {
        str = str.replace(new RegExp(high, "ig"), regWord => {
          return '<font color="' + highWords[high] + '">' + regWord + "</font>";
        });
      }
    }
  }
  return str;
}

function getCurrentUser() {
  if (JSON.parse(sessionStorage.getItem("currentUser"))) {
    return JSON.parse(sessionStorage.getItem("currentUser"));
  }
  return null;
}

function isAdmin() {
  let currentUser = getCurrentUser();
  //判断是否
  if (currentUser) {
    return currentUser.admin;
  }
  return false;
}

//文档信息复制
function copyMessage(doc, vue) {
  let copyTemplate =
    "时间：${time}\n来源：${source}\n作者：${author}\n标题：${title}\n内容：${content}\n链接：${url}";

  let customCopyTemplate = localStorage.getItem("copytemplate");
  if (customCopyTemplate) {
    copyTemplate = customCopyTemplate;
  }
  for (let key in doc) {
    copyTemplate = copyTemplate.replace("${" + key + "}", doc[key]);
  }
  vue.$copyText(copyTemplate).then(() => {
    vue.$message({
      message: "复制成功！",
      type: "success"
    });
  });
}

export {
  resetHotWords,
  highStr,
  getHotWords,
  duplicateWords,
  getCurrentUser,
  isAdmin,
  copyMessage
};
