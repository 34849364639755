<template>
  <div class="msglist">
    <div class="header">
      <button @click="updateWordDialog()">新增</button>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="tagName" label="标签" width="180">
      </el-table-column>
      <el-table-column
        prop="words"
        :formatter="fmtWordsShow"
        label="词库"
        width="680"
      >
      </el-table-column>
      <el-table-column prop="color" label="高亮颜色" width="180">
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="updateWordDialog(scope.row)"
            type="text"
            size="small"
            >修改</el-button
          >

          <el-button @click="delWordLib(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page.sync="pageBean.number"
      :page-size.sync="pageBean.size"
      :page-sizes="[10, 20, 30, 50]"
      layout="total,sizes,prev, pager, next"
      :total.sync="pageBean.totalElements"
    >
    </el-pagination>

    <el-dialog title="修改词库" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标签">
          <el-input v-model="form.tagName"></el-input>
        </el-form-item>

        <el-form-item label="词库">
          <el-input type="textarea" rows="2" v-model="form.words"></el-input>
        </el-form-item>

        <el-form-item label="高亮颜色">
          <el-input v-model="form.color"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="
              dialogFormVisible = false;
              updateWord();
            "
            >修改</el-button
          >
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { duplicateWords } from "@/utils/api.js";

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      },
      dialogFormVisible: false,
      form: {}
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    updateWordDialog(word) {
      if (word) {
        this.form = word;
      } else {
        this.form = {};
      }
      this.dialogFormVisible = true;
    },
    updateWord() {
      this.form.words = duplicateWords(this.form.words);
      this.$request.post("/api/sys/resource/wordlib", this.form).then(() => {
        this.$message.success("修改成功");
        this.requestData();
      });
    },
    delWordLib(data) {
      this.$confirm(
        "此操作将删除“" + data.tagName + "”词库, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$request
          .delete("/api/sys/resource/wordlib/" + data.id)
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.requestData();
          });
      });
    },
    fmtWordsShow(row) {
      if (row.words && row.words.length > 50) {
        return row.words.substring(0, 50) + "...";
      }
      return row.words;
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    requestData() {
      this.loading = true;
      this.$request
        .get("/api/sys/resource/wordlib", {
          page: this.pageBean.number,
          size: this.pageBean.size
        })
        .then(res => {
          res.data.number = res.data.number + 1;
          this.pageBean = res.data;
          this.loading = false;
          this.tableData = this.pageBean.content;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.msglist {
  width: 80%;
}

.header {
  padding-right: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-input {
    width: 300px;
    margin-right: 20px;
  }
  button {
    height: 36px;
    width: 80px;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    background: none;
  }
  // border-bottom: 1px solid #5090f1;
}
</style>
