<template>
  <div class="detailPage">
    <div class="detailContent">
      <div class="detail-top">
        <div class="detail-header">
          <div class="detail-title" v-html="highString(detailData.title)"></div>
          <div class="detail-infos">
            <span>发布时间：</span
            ><span class="time" v-text="detailData.time"></span>
            <span>入库时间：</span
            ><span class="time" v-text="detailData.spiderTime"></span>
            <span>/</span>
            <span class="username">
              <i class="el-icon-user user-icon" v-text="detailData.author"></i>
            </span>
            <span>/</span>
            <a
              target="_blank"
              v-text="detailData.source"
              :href="detailData.url"
            ></a>
          </div>
        </div>
        <div class="closebtn" @click="closePage()">
          <span>关闭 </span>
        </div>
      </div>
      <div class="detail-artical" v-html="highString(detailData.content)"></div>
    </div>
    <div class="detail-btn">
      <ul>
        <li
          v-for="(item, index) in btns"
          :key="index"
          style="cursor:pointer"
          @click="eventByType(item.text)"
        >
          <i :class="item.icon" class="icon"></i>
          <span v-text="item.text"></span>
        </li>
      </ul>
    </div>

    <el-dialog width="20%" title="标签选择" :visible.sync="dialogFormVisible">
      <el-select
        filterable
        allow-create
        v-model="selectTag"
        placeholder="请选择或输入标签"
      >
        <el-option
          :key="index"
          v-for="(value, key, index) in tags"
          :label="value"
          :value="value"
        ></el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            isCollectDialog ? collectDoc() : authorMointer();
            dialogFormVisible = false;
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { highStr, getHotWords, copyMessage } from "../utils/api.js";

export default {
  data() {
    return {
      detailData: {},
      btns: [
        {
          icon: "el-icon-notebook-2",
          text: "翻译"
        },
        {
          icon: "el-icon-document-copy",
          text: "复制"
        },
        {
          icon: "el-icon-star-off",
          text: "收藏"
        },
        {
          icon: "el-icon-bell",
          text: "预警"
        },
        {
          icon: "el-icon-link",
          text: "原文"
        }
      ],
      tags: [],
      selectTag: "",
      dialogFormVisible: false,
      hotWords: [],
      supportplatform: ["推特"],
      isCollectDialog: true,
      notCommonSe: []
    };
  },
  async created() {
    this.hotWords = await getHotWords();
    let id = this.$route.query.id;
    this.$request.get(`/api/doc/${id}`).then(data => {
      this.detailData = data.data;
      if (this.supportplatform.indexOf(this.detailData.source) != -1) {
        this.btns.push({
          icon: "el-icon-magic-stick",
          text: "追踪"
        });
      }
    });

    //特殊搜索任务 获取非公共采集引擎
    this.$request
      .get("/api/crawl/se", {
        page: 1,
        size: 100,
        common: false,
        keyword: "文主"
      })
      .then(res => {
        this.notCommonSe = res.data.content;
        this.notCommonSe.map(se => {
          se.name = se.name.replace("文主-", "");
        });
      });
  },
  methods: {
    closePage() {
      //关闭当前页
      window.close();
    },
    highString(str) {
      return highStr(str, this.hotWords);
    },
    eventByType(type) {
      switch (type) {
        case "翻译":
          this.translate(this.detailData);
          break;
        case "复制":
          this.copyDetail(this.detailData);
          break;
        case "收藏":
          this.collectDocDialog();
          break;
        case "预警":
          this.alarmDoc(this.detailData);
          break;
        case "原文":
          this.openUrl(this.detailData);
          break;
        case "追踪":
          this.authorMointeriDialog();
      }
    },
    translate(doc) {
      this.$request
        .post("/api/sys/resource/translate", { docId: doc.id, transCnt: true })
        .then(res => {
          doc.title = res.data[0];
          doc.content = res.data[1];
        });
    },
    collectDoc() {
      //批量收藏数据
      this.$request
        .post("/api/doc/collect", {
          action: "collect",
          ids: this.selectId,
          tag: this.selectTag
        })
        .then(() => {
          this.$message.success("收藏成功!");
        });
    },

    collectDocDialog() {
      this.$request.get("/api/doc/collect", { action: "tags" }).then(res => {
        this.selectTag = null;
        this.tags = res.data;
        this.dialogFormVisible = true;
        this.isCollectDialog = true;
      });
    },

    authorMointeriDialog() {
      this.$request.get("/api/crawl/authorMonitor/tags").then(res => {
        this.selectTag = null;
        this.tags = res.data;
        this.dialogFormVisible = true;
        this.isCollectDialog = false;
      });
    },
    authorMointer() {
      let doc = this.detailData;
      let source = doc.source;
      if (source == "推特") {
        let se = this.notCommonSe.filter(se => se.name == "推特")[0];
        let authorUrl = doc.url.substring(0, doc.url.indexOf("/status"));
        let form = {
          name: doc.author,
          url: authorUrl,
          se: { id: se.id },
          enable: true,
          tag: this.selectTag,
          alarm: true
        };

        this.$request.post("/api/crawl/authorMonitor", form).then(() => {
          this.$message.success("用户追踪成功");
        });
      }
    },

    copyDetail(doc) {
      copyMessage(doc, this);
    },
    openUrl(doc) {
      //打开文章链接
      window.open(doc.url);
    },
    alarmDoc(doc) {
      //批量收藏数据
      this.$request
        .post("/api/doc/alarm", {
          action: "add",
          ids: doc.id
        })
        .then(() => {
          this.$message({
            message: "预警成功！",
            type: "success"
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.icon {
  margin-top: 24px;
}
.detailPage {
  height: 100%;
  background: #f2f3f7;
  overflow: hidden;
  padding: 20px 0;
  .detailContent {
    width: 70%;
    height: calc(100% - 40px);
    overflow: auto;
    background: #fff;
    margin: 0px auto;
    text-align: left;
    &::-webkit-scrollbar {
      display: none;
    }
    .detail-top {
      // width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid #f0f0f0;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .detail-header {
        width: 90%;
      }
      .closebtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          padding: 10px 20px;
          background: #177fc8;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          font-size: 15px;
        }
      }

      .detail-title {
        height: 36px;
        line-height: 36px;
        color: #222222;
        font-size: 1.5rem;
        margin: 20px 85px 0 0;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .detail-infos {
        margin: 20px 0 10px 0;
        span {
          display: inline-block;
          margin-right: 8px;
          color: #999999;
          font-size: 14px;
          height: 22px;
          line-height: 22px;
        }
        a {
          color: #5090f1;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
    .detail-artical {
      padding: 10px 20px 30px;
      word-break: break-all;
      font-size: 16px;
      color: #222222;
      line-height: 1.8;
    }
  }
  .detail-btn {
    width: 150px;
    // height: 400px;
    position: absolute;
    top: 200px;
    margin-left: 87%;
    background: #fff;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        height: 60px;
        line-height: 60px;
        display: flex;
        align-items: center;
        margin: 0 30px;
        border-bottom: 1px solid #e5e5e5;
        i {
          display: inline-block;
          width: 40px;
          height: 40px;
        }
        span {
          color: #000;
          font-size: 17px;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
