<template>
  <div class="early-warning">
    <div class="header">
      <div class="header-left">
        <el-radio-group v-model="timeType" size="small">
          <el-radio-button label="今天"></el-radio-button>
          <el-radio-button label="24小时"></el-radio-button>
          <el-radio-button label="三天内"></el-radio-button>
          <el-radio-button label="七天内"></el-radio-button>
          <el-radio-button label="自定义"></el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="mini"
          v-if="timeType == '自定义'"
          v-model="rangTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          @change="requestData"
        >
        </el-date-picker>
      </div>

      <div class="header-right">
        <el-input
          size="mini"
          placeholder="请输入搜索条件"
          v-model="searchRule"
          class="input-with-select"
          @change="requestData"
        >
          <el-select v-model="searchScope" slot="prepend" placeholder="请选择">
            <el-option label="自定义" value=""></el-option>
            <el-option label="来源" value="source"></el-option>
            <el-option label="标题" value="title"></el-option>
            <el-option label="链接" value="url"></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="requestData"
          ></el-button>
          <el-button
            slot="append"
            icon="el-icon-delete"
            @click="deleteData"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="content">
      <el-table
        v-loading="loading"
        :data="tableData"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column type="selection" min-width="3"> </el-table-column>
        <el-table-column type="index" min-width="3"> </el-table-column>
        <el-table-column prop="title" label="标题" min-width="64">
          <template slot-scope="scope">
            <p
              style="cursor:pointer;font-weight:700"
              v-html="subTitle(scope.row.title)"
              @click="goDetail(scope.row.id)"
            ></p>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" min-width="10">
        </el-table-column>
        <el-table-column prop="spiderTime" label="抓取时间" min-width="10">
        </el-table-column>
        <el-table-column prop="source" label="来源" min-width="10">
          <template slot-scope="scope">
            <el-link
              :href="scope.row.url"
              target="_blank"
              v-text="scope.row.source"
            ></el-link>
          </template>
        </el-table-column>
        <el-table-column prop="spiderSource" label="采集源" min-width="10">
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="pageBean.number"
        :page-size.sync="pageBean.size"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,sizes,prev, pager, next"
        :total.sync="pageBean.totalElements"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      searchRule: "",
      searchScope: "",
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      },
      rangTime: "",
      timeType: "今天"
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    openUrl(url) {
      //打开文章链接
      window.open(url);
    },
    subTitle(title) {
      if (title.length > 100) {
        title = title.substring(0, 100) + "...";
      }
      return title;
    },
    goDetail(id) {
      let routeData = this.$router.resolve(`/detail?id=${id}`);
      window.open(routeData.href, "_blank");
      // this.$router.push({ name: "Detail", params: data });
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    getQueryParam() {
      if (!this.searchRule) return;

      this.loading = true;

      let startTime = new Date();
      let endTime = new Date();

      switch (this.timeType) {
        case "今天":
          startTime.setHours(0, 0, 0);
          break;
        case "24小时":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 1);
          break;
        case "三天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 3);
          break;
        case "七天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 7);
          break;
        case "自定义":
          startTime = this.rangTime[0];
          endTime = this.rangTime[1];
          if (!startTime || !endTime) {
            //未选择时间 默认选中今天
            this.timeType = "今天";
            this.requestData();
          }
          break;
      }

      let queryRule = this.searchRule;
      if (this.searchScope) {
        queryRule = this.searchScope + ":" + this.searchRule;
      }

      let queryParam = {
        rule: queryRule,
        page: this.pageBean.number,
        size: this.pageBean.size,
        startTime: startTime.format(),
        endTime: endTime.format(),
        timeField: "time"
      };
      return queryParam;
    },
    deleteData() {
      this.$confirm("此操作将删除指定条件的数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$request.post("/api/doc/delete", this.getQueryParam()).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.requestData();
        });
      });
    },
    requestData() {
      this.$request.post("/api/doc", this.getQueryParam()).then(res => {
        res.data.number = res.data.number + 1;
        this.pageBean = res.data;
        this.tableData = this.pageBean.content;
        this.loading = false;
      });
    }
  }
};
</script>
<style>
.early-warning .content-header-search .el-input {
  width: 200px !important;
}
.early-warning .dialog {
  top: 0px;
  right: -320px;
}
.early-warning .el-radio-button--small .el-radio-button__inner {
  padding: 5px 15px;
}
/* .early-warning .el-input__inner {
  width: 180px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  font-size: 0.75rem;
  border-radius: 2px;
  padding: 4px 0 4px 8px;
} */
</style>
<style lang="scss" scoped>
.early-warning {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  //   display: flex;
  .header {
    padding: 0 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
    }
    .header-right {
      display: flex;
      align-items: center;
    }
    button {
      height: 36px;
      width: 80px;
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      background: none;
    }
    .el-range-editor.el-input__inner {
      margin-left: 10px;
    }
  }
  .content {
    padding: 0 40px;
    .el-pagination {
      margin-top: 30px;
    }
    //   position: relative;
    //   width: 70%;
    //   height: 100%;
    //   margin: 20px 10px 0 200px;
    //   background: #fff;
    .content-header {
      margin: 0 20px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      border-bottom: 1px solid #5090f1;
      display: flex;
      align-items: center;
      .content-header-item {
        flex: 1;
      }
      .content-header-title {
        text-align: left;
      }
      .content-header-search {
        text-align: right;
      }
      .content-header-filter {
        margin-left: 20px;
        div {
          widows: 60px;
          height: 20px;
          line-height: 20px;
          padding: 0 4px;
          border: 1px solid #dadcdf;
          border-radius: 2px;
          color: #5090f1;
          font-size: 12px;
        }
      }
    }
  }

  .el-table__body {
    padding: 0px;
  }

  .el-select .el-input {
    width: 130px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
}
</style>
