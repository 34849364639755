<template>
  <el-row class="tac">
    <el-col :span="4">
      <el-menu
        :router="true"
        :default-active="currentPath"
        class="el-menu-vertical-demo"
      >
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-guide"></i>
            <span>抓取任务</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/admin/crawlconfig/key"
              >关键词任务</el-menu-item
            >
            <el-menu-item index="/admin/crawlconfig/specialKey"
              >特殊搜索任务</el-menu-item
            >
            <el-menu-item index="/admin/crawlconfig/seedSite"
              >种子任务</el-menu-item
            >
            <el-menu-item index="/admin/crawlconfig/site"
              >站点任务</el-menu-item
            >
            <el-menu-item index="/admin/crawlconfig/se"
              >搜索引擎配置</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item index="/admin/msgboardlist">
          <i class="el-icon-message"></i>
          <span slot="title">留言管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/specialtreenode">
          <i class="el-icon-files"></i>
          <span slot="title">专项设置</span>
        </el-menu-item>
        <el-menu-item index="/admin/wordlib">
          <i class="el-icon-tickets"></i>
          <span slot="title">词库设置</span>
        </el-menu-item>
        <el-menu-item index="/admin/wordlink">
          <i class="el-icon-paperclip"></i>
          <span slot="title">拦截链设置</span>
        </el-menu-item>

        <el-menu-item index="/admin/userlist">
          <i class="el-icon-user"></i>
          <span slot="title">用户管理</span>
        </el-menu-item>

        <el-menu-item index="/admin/datamanage">
          <i class="el-icon-coin"></i>
          <span slot="title">数据管理</span>
        </el-menu-item>

        <el-menu-item index="/admin/logmanage">
          <i class="el-icon-notebook-1"></i>
          <span slot="title">日志查看</span>
        </el-menu-item>

        <el-menu-item index="/admin/systemsetting">
          <i class="el-icon-cpu"></i>
          <span slot="title">系统设置</span>
        </el-menu-item>
      </el-menu>
    </el-col>

    <el-col :span="20">
      <router-view></router-view>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      currentPath: "/admin/crawlconfig/key"
    };
  },
  mounted: function() {
    if (this.$route.name == "AdminPanel") {
      this.$router.push(this.currentPath);
    } else {
      this.currentPath == this.$route.name.path;
    }
  }
};
</script>
