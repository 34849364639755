import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Detail from "../components/Detail.vue";
import Login from "../views/Login.vue";
import AdminPanel from "@/components/admin/AdminPanel.vue";
import MessageBoard from "@/components/MessageBoard.vue";
import Monitor from "@/components/Monitor.vue";
import EarlyWarning from "@/components/EarlyWarning.vue";
import CollectMsg from "@/components/CollectMsg.vue";
import AuthorMonitor from "@/components/AuthorMonitor.vue";
import SiteInfoPage from "@/components/SiteInfoPage.vue";
import NetSearch from "@/components/NetSearch.vue";
import ChatAI from "@/components/ChatAI.vue";
import Report from "@/components/Report.vue";

//管理员控制面板
import CrawlConfig from "@/components/admin/CrawlConfig.vue";
import MessageBoardList from "@/components/admin/MessageBoardList.vue";
import SpecialTreeNode from "@/components/admin/SpecialTreeNode.vue";
import WordLib from "@/components/admin/WordLib.vue";
import WordLink from "@/components/admin/WordLink.vue";
import UserList from "@/components/admin/UserList.vue";
import DataManage from "@/components/admin/DataManage.vue";
import LogManage from "@/components/admin/LogList.vue";
import SystemSetting from "@/components/admin/SystemSetting.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "monitor",
        name: "Monitor",
        meta: { title: "信息监测" },
        component: Monitor
      },
      {
        path: "report",
        name: "Report",
        meta: { title: "舆情简报" },
        component: Report
      },
      {
        path: "earlywarning",
        name: "EarlyWarning",
        meta: { title: "预警信息" },
        component: EarlyWarning
      },
      {
        path: "ChatAI",
        name: "ChatAI",
        meta: { title: "AI" },
        component: ChatAI
      },
      {
        path: "collectmsg",
        name: "CollectMsg",
        meta: { title: "收藏信息" },
        component: CollectMsg
      },
      {
        path: "authormonitor",
        name: "AuthorMonitor",
        meta: { title: "文主追踪" },
        component: AuthorMonitor
      },
      {
        path: "siteinfopage",
        name: "SiteInfoPage",
        meta: { title: "海外导航" },
        component: SiteInfoPage
      },
      {
        path: "netSearch",
        name: "NetSearch",
        meta: { title: "全网搜索" },
        component: NetSearch
      },
      {
        path: "MessageBoard",
        name: "MessageBoard",
        meta: { title: "留言板" },
        component: MessageBoard
      },
      {
        path: "admin",
        name: "AdminPanel",
        meta: { title: "控制面板" },
        component: AdminPanel,
        children: [
          {
            path: "crawlconfig/se",
            name: "SearchEngineConfig",
            meta: { title: "搜索引擎配置" },
            component: CrawlConfig,
            props: { checkType: "se" }
          },
          {
            path: "crawlconfig/key",
            name: "CrawlKeyConfig",
            meta: { title: "关键词配置" },
            component: CrawlConfig,
            props: { checkType: "key" }
          },
          {
            path: "crawlconfig/specialKey",
            name: "SpecialKeyConfig",
            meta: { title: "特殊关键词配置" },
            component: CrawlConfig,
            props: { checkType: "specialKey" }
          },
          {
            path: "crawlconfig/seedSite",
            name: "SeedSiteConfig",
            meta: { title: "种子配置" },
            component: CrawlConfig,
            props: { checkType: "seedSite" }
          },
          {
            path: "crawlconfig/site",
            name: "SiteConfig",
            meta: { title: "站点配置" },
            component: CrawlConfig,
            props: { checkType: "site" }
          },
          {
            path: "msgboardlist",
            name: "MessageBoardList",
            meta: { title: "留言列表" },
            component: MessageBoardList
          },
          {
            path: "specialtreenode",
            name: "SpecialTreeNode",
            meta: { title: "专项设置" },
            component: SpecialTreeNode
          },
          {
            path: "wordlib",
            name: "WordLib",
            meta: { title: "词库设置" },
            component: WordLib
          },
          {
            path: "wordlink",
            name: "WordLink",
            meta: { title: "拦截链设置" },
            component: WordLink
          },
          {
            path: "userlist",
            name: "UserList",
            meta: { title: "用户管理" },
            component: UserList
          },
          {
            path: "datamanage",
            name: "DataManage",
            meta: { title: "数据管理" },
            component: DataManage
          },
          {
            path: "logmanage",
            name: "LogManage",
            meta: { title: "日志管理" },
            component: LogManage
          },
          {
            path: "systemsetting",
            name: "SystemSetting",
            meta: { title: "系统设置" },
            component: SystemSetting
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    meta: { title: "登录" },
    component: Login,
    props: true
  },
  {
    //信息详情
    path: "/Detail",
    name: "Detail",
    meta: { title: "详情" },
    component: Detail
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.name == "Login") {
    next();
  } else {
    const token = sessionStorage.getItem("token");
    if (token) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
