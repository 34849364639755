<template>
  <div class="sitebox">
    <div class="sorts">
      <el-divider style="width:70%;">重点网址</el-divider>
      <ul style="display:block;">
        <li v-for="(item, index) in headerSite" :key="index">
          <a
            :title="item.name"
            :href="item.homeUrl"
            target="_blank"
            v-text="showName(item.name)"
          ></a>
        </li>
      </ul>
      <el-divider style="width:70%;">常用网址</el-divider>
      <ul style="display:block;">
        <li v-for="(item, index) in customSite" :key="index">
          <i
            @click="delSiteInfo(item)"
            style="float:right;margin-top:-4px;color:white;"
            class="el-icon-close"
          ></i>
          <a
            :title="item.name"
            :href="item.homeUrl"
            target="_blank"
            v-text="showName(item.name)"
          ></a>
        </li>
        <li>
          <a
            title="新增"
            href="#"
            @click="
              form = {};
              dialogFormVisible = true;
            "
            ><i class="el-icon-circle-plus-outline"></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="sorts">
      <el-divider style="width:70%;">全部网址</el-divider>
      <ul>
        <li v-for="(item, index) in siteInfoList" :key="index">
          <i
            v-if="currentUser.admin"
            @click="delSiteInfo(item)"
            style="float:right;margin-top:-4px;color:white;"
            class="el-icon-close"
          ></i>
          <a
            :title="item.name"
            :href="item.homeUrl"
            target="_blank"
            v-text="showName(item.name)"
          ></a>
        </li>
      </ul>
    </div>

    <el-dialog width="30%" title="新增网址" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="网站名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="网站网址">
          <el-input v-model="form.homeUrl"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="
              dialogFormVisible = false;
              addSiteInfo();
            "
            >增加</el-button
          >
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { getCurrentUser } from "@/utils/api.js";

export default {
  data() {
    return {
      siteInfoList: [],
      headerSite: [],
      customSite: [],
      form: {},
      dialogFormVisible: false,
      currentUser: {}
    };
  },
  created() {
    this.resetSiteInfo();
    this.currentUser = getCurrentUser();
  },
  methods: {
    delSiteInfo(item) {
      this.$confirm(
        "此操作将删除“" + item.name + "”导航站点, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$request
          .delete("/api/sys/resource/siteInfo/" + item.id)
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.resetSiteInfo();
          });
      });
    },

    resetSiteInfo() {
      this.$request
        .get("/api/sys/resource/siteInfo", { page: 1, size: 1000 })
        .then(res => {
          res.data.content = res.data.content.sort((a, b) =>
            a.name.localeCompare(b.name, "zh")
          );
          this.customSite = res.data.content.filter(site => {
            return site.belong != null;
          });
          this.siteInfoList = res.data.content.filter(site => {
            return site.belong == null;
          });
          this.headerSite = this.siteInfoList.filter(site => {
            return "header" == site.tag;
          });
        });
    },
    showName(name) {
      if (name.length > 5) {
        name = name.substring(0, 5);
      }
      return name;
    },
    addSiteInfo() {
      this.form.name = this.form.name.trim();
      this.form.homeUrl = this.form.homeUrl.trim();
      if (!this.form.homeUrl.startsWith("http")) {
        this.form.homeUrl = "http://" + this.form.homeUrl;
      }
      this.$request.post("/api/sys/resource/siteInfo", this.form).then(() => {
        this.resetSiteInfo();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.sitebox {
  height: 95%;
  background: #fff;
  overflow: auto;
  .search-box {
    padding: 10px 0;
    input {
      width: 40%;
      height: 50px;
      border: 2px solid orange;
      border-radius: 3px;
      font-size: 20px;
      text-align: center;
      outline: none;
    }
    input::-webkit-input-placeholder {
      color: rgb(24, 223, 206);
      font-size: 20px;
      text-align: center;
    }
  }
  .site-box {
    margin: 20px 0;
    .sites {
      width: 70%;
      margin: 0 auto;
      border: 2px solid #6a7064;
      padding: 15px 30px;
      background: #f2f2f2;
      text-align: left;
      box-sizing: border-box;
      .site-line {
        margin-bottom: 10px;
      }
      .site {
        display: inline-block;
        padding: 10px;
        background: rgb(146, 156, 187);
        margin-right: 15px;
        margin-bottom: 10px;
        // display: inline-flex;
        // justify-content: space-between;
        a {
          text-decoration: none;
          display: inline-block;
          // background: #85949b;
          color: white;
        }
      }
    }
  }
  .sorts {
    width: 70%;
    margin: 0 auto;
    border: 2px solid #bcc5ce;
    padding: 5px 0px;
    margin-bottom: 50px;
    // display: flex;
    ul {
      width: 100%;
      list-style: none;
      text-align: left;
      li {
        cursor: pointer;
        display: inline-block;
        width: 12%;
        border: 1px solid #b2dfff;
        text-align: center;
        padding: 5px 2px;
        margin-bottom: 2px;
        margin-right: 1px;
        word-wrap: break-word;
        a {
          display: block;
          text-decoration: none;
          color: #333;
        }
      }
      li:hover {
        background: #409eff;
        a {
          color: orange;
        }
      }
    }
  }
}
</style>
