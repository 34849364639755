<template>
  <div class="collectMsg">
    <div class="header">
      <el-select
        v-model="selectTag"
        @change="requestData"
        placeholder="请选择标签"
        style="margin-right:50px"
      >
        <el-option
          :key="index"
          v-for="(value, key, index) in tags"
          :label="value"
          :value="value"
        ></el-option>
      </el-select>

      <button @click="exportExcel">导出</button>
      <button @click="cancelCollect">取消收藏</button>
    </div>
    <div class="content">
      <el-table
        v-loading="loading"
        :data="tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" min-width="3"> </el-table-column>
        <el-table-column type="index" min-width="3"> </el-table-column>
        <el-table-column prop="title" label="标题" min-width="58">
          <template slot-scope="scope">
            <p
              style="cursor:pointer;font-weight:700"
              v-html="subTitle(scope.row.title)"
              @click="goDetail(scope.row.id)"
            ></p>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="发布时间" min-width="10">
        </el-table-column>
        <el-table-column prop="source" label="来源" min-width="6">
        </el-table-column>
        <el-table-column prop="tag" label="标签" min-width="6">
        </el-table-column>
        <el-table-column prop="collectTime" label="收藏时间" min-width="10">
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="10">
          <template slot-scope="scope">
            <el-button
              @click="delAlarmMsg(scope.row.id)"
              type="text"
              size="small"
              >取消收藏</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="pageBean.number"
        :page-size.sync="pageBean.size"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,sizes,prev, pager, next"
        :total.sync="pageBean.totalElements"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
import { convertRes2Blob } from "../utils/constants";
import { highStr } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      selectTag: null,
      tags: null,
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      },
      multipleSelection: []
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    subTitle(title) {
      if (title.length > 150) {
        title = title.substring(0, 150) + "...";
      }
      return highStr(title);
    },
    goDetail(id) {
      let routeData = this.$router.resolve(`/detail?id=${id}`);
      window.open(routeData.href, "_blank");
      // this.$router.push({ name: "Detail", params: data });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportExcel() {
      //导出为excel
      if (this.multipleSelection.length > 0) {
        let checkIds = [];
        this.multipleSelection.forEach(doc => {
          checkIds.push(doc.id);
        });
        //数据导出
        this.$request
          .request({
            url: "/api/doc/exportExcel",
            method: "post",
            responseType: "blob",
            data: Qs.stringify({
              fileName: "舆情数据",
              type: "collectMsg",
              ids: checkIds.join(",")
            })
          })
          .then(res => {
            convertRes2Blob(res, "预警数据.xls");
          });
      } else {
        this.$message.warning("请至少选中一条信息!");
      }
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    cancelCollect() {
      if (this.multipleSelection.length > 0) {
        let checkIds = [];
        this.multipleSelection.forEach(doc => {
          checkIds.push(doc.id);
        });
        let ids = checkIds.join(",");
        this.delAlarmMsg(ids);
      } else {
        this.$message.warning("请至少选中一条信息!");
      }
    },
    delAlarmMsg(ids) {
      this.$request
        .post("/api/doc/collect", { ids: ids, action: "uncollect" })
        .then(() => {
          this.$message.success("取消成功");
          this.requestData();
        });
    },
    setTableData() {
      //设置表格数据
      this.tableData = [];
      this.pageBean.content.map(doc => {
        this.tableData.push({
          title: doc.title,
          time: doc.time,
          source: doc.source,
          collectTime: doc.collectTime,
          id: doc.id,
          tag: doc.tag
        });
      });
    },
    requestData() {
      this.loading = true;
      this.$request
        .post("/api/doc/collect", {
          page: this.pageBean.number,
          size: this.pageBean.size,
          tag: this.selectTag
        })
        .then(res => {
          res.data.number = res.data.number + 1;
          this.pageBean = res.data;
          this.setTableData();
          this.loading = false;
        });

      this.$request.get("/api/doc/collect", { action: "tags" }).then(res => {
        this.tags = res.data;
      });
    }
  }
};
</script>
<style>
/* .early-warning .content-header-search .el-input {
  width: 200px !important;
}
.early-warning .dialog {
  top: 0px;
  right: -320px;
} */
</style>
<style lang="scss" scoped>
.collectMsg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  overflow: auto;
  .header {
    padding-right: 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-input {
      width: 300px;
      margin-right: 20px;
    }
    button {
      height: 36px;
      width: 80px;
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      background: none;
    }
    // border-bottom: 1px solid #5090f1;
  }
  .content {
    .el-pagination {
      margin-top: 30px;
    }
  }
}

.early-warning {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #f2f3f7;
  overflow: hidden;
  //   display: flex;
  .content {
    position: relative;
    width: 70%;
    height: 100%;
    margin: 20px 10px 0 200px;
    background: #fff;
    .content-header {
      margin: 0 20px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      border-bottom: 1px solid #5090f1;
      display: flex;
      align-items: center;
      .content-header-item {
        flex: 1;
      }
      .content-header-title {
        text-align: left;
      }
      .content-header-search {
        text-align: right;
      }
      .content-header-filter {
        margin-left: 20px;
        div {
          widows: 60px;
          height: 20px;
          line-height: 20px;
          padding: 0 4px;
          border: 1px solid #dadcdf;
          border-radius: 2px;
          color: #5090f1;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
