<template>
  <div class="dialog">
    <div class="dialog-default">
      <div class="filter-items">
        <div class="items-title">时间范围：</div>
        <div class="items-option">
          <el-radio-group
            v-model="timeType"
            size="small"
            @change="checkTimeType"
          >
            <el-radio-button
              v-for="(item, index) in timeTypes"
              :key="index"
              :label="item"
            ></el-radio-button>
          </el-radio-group>

          <div v-if="timeType == '自定义'">
            <el-date-picker
              size="mini"
              v-model="rangeStartTime"
              type="datetime"
              placeholder="开始日期"
              @change="use"
            >
            </el-date-picker>
            至
            <el-date-picker
              size="mini"
              v-model="rangeEndTime"
              type="datetime"
              placeholder="结束日期"
              @change="use"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="filter-items">
        <div class="items-title">相似信息：</div>
        <div class="items-option">
          <el-radio-group
            v-model="selectAlarm"
            size="small"
            @change="checkDuplicateType"
          >
            <el-radio-button label="0">去重</el-radio-button>
            <el-radio-button label="1">不去重</el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="filter-items">
        <div class="items-title">信息类型：</div>
        <div class="items-option">
          <el-checkbox-group
            v-model="selectType"
            size="small"
            @change="changeSelectDocType"
          >
            <el-checkbox-button
              v-for="type in docTypes"
              :label="type"
              :key="type"
              >{{ type }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>

      <div class="filter-items">
        <div class="items-title">译文信息：</div>
        <div class="items-option">
          <el-radio-group
            size="small"
            v-model="translate"
            @change="translateCnt"
          >
            <el-radio-button label="true">显示</el-radio-button>
            <el-radio-button label="false">不显示</el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="filter-items">
        <div class="items-title">时间排序：</div>
        <div class="items-option">
          <el-radio-group
            size="small"
            v-model="sortTimeFiled"
            @change="getBySortTime"
          >
            <el-radio-button label="time">发布时间</el-radio-button>
            <el-radio-button label="spiderTime">入库时间</el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="filter-items">
        <div class="items-title">敏感数据：</div>
        <div class="items-option">
          <el-checkbox @change="getByMingan" v-model="mingan">敏感</el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timeTypes: ["今天", "24小时", "三天内", "七天内", "自定义"],
      timeType: "三天内",
      rangeStartTime: null,
      rangeEndTime: null,
      selectAlarm: 1,
      lastSelectAlarm: 1,
      showDatePicker: true,
      translate: false,
      sortTimeFiled: "time",
      mingan: false,
      selectType: [],
      docTypes: ["新闻", "推特", "网页", "视频", "电报"]
    };
  },
  props: {
    currentNode: {
      type: Object
    },
    searchKey: {
      type: Object
    }
  },
  methods: {
    checkDuplicateType() {
      this.use();
    },
    changeSelectDocType() {
      this.use();
    },
    translateCnt() {
      this.use();
    },
    getBySortTime() {
      this.use();
    },
    getByMingan() {
      this.use();
    },
    checkTimeType() {
      if (this.timeType != "自定义") {
        this.use();
      }
    },
    use() {
      this.lastSelectAlarm = this.selectAlarm;

      if (this.currentNode) {
        localStorage.setItem(
          `alarmFlag${this.currentNode.id}`,
          this.selectAlarm
        );
        localStorage.setItem(
          `timeSort${this.currentNode.id}`,
          this.sortTimeFiled
        );
        localStorage.setItem(
          `selectType${this.currentNode.id}`,
          this.selectType.join(",")
        );
        localStorage.setItem(`mingan_${this.currentNode.id}`, this.mingan);
      }
      let startTime = new Date();
      let nowTime = new Date();
      let endTime = null;

      switch (this.timeType) {
        case "今天":
          startTime.setHours(0, 0, 0);
          break;
        case "24小时":
          startTime.setTime(nowTime - 3600 * 1000 * 24 * 1);
          break;
        case "三天内":
          startTime.setTime(nowTime - 3600 * 1000 * 24 * 3);
          break;
        case "七天内":
          startTime.setTime(nowTime - 3600 * 1000 * 24 * 7);
          break;
        case "自定义":
          if (!this.rangeStartTime || !this.rangeEndTime) {
            return;
          }
          startTime = this.rangeStartTime;
          endTime = this.rangeEndTime;
          break;
      }
      endTime = endTime != null ? endTime.format() : new Date().format();
      startTime = startTime.format();
      let alarmFlag = this.selectAlarm == 0;

      let requestData = {
        category: this.selectType.join(","),
        startTime,
        duplicate: alarmFlag,
        translate: this.translate,
        timeField: this.sortTimeFiled,
        risis: this.mingan ? "2" : ""
      };

      if (endTime != null) {
        requestData.endTime = endTime;
      }

      let rules = new Array(); //查询的条件

      if (this.searchKey) {
        rules.push(this.searchKey);
      }
      if (rules.length > 0) {
        requestData.rule = rules.join(",");
      }

      this.$emit("changeParams", requestData);
    },
    controlShowDate() {
      this.showDatePicker = true;
    }
  },
  watch: {
    currentNode(newval) {
      this.selectAlarm = 1;
      this.lastSelectAlarm = 1;
      this.timeType = "三天内";
      this.selectType = [];
      this.mingan = false;
      let alarmFlag = localStorage.getItem(`alarmFlag${newval.id}`);
      let timeSort = localStorage.getItem(`timeSort${newval.id}`);
      let selectTypeStr = localStorage.getItem(`selectType${newval.id}`);
      let minganlocal = localStorage.getItem(`mingan_${newval.id}`);
      if (alarmFlag) {
        this.selectAlarm = parseInt(alarmFlag);
        this.lastSelectAlarm = parseInt(alarmFlag);
      }
      if (selectTypeStr) {
        this.selectType = selectTypeStr.split(",");
      }
      if (minganlocal) {
        this.mingan = minganlocal == "true";
      }
      this.rangeEndTime = null;
      this.rangeStartTime = null;
      this.translate = false;
      this.sortTimeFiled = timeSort ? timeSort : "time";
    },
    searchKey() {
      this.use();
    }
  }
};
</script>
<style>
.dialog .dialog-default .el-input {
  width: 180px;
}
.dialog .dialog-default .filter-items .el-input__inner {
  width: 180px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  font-size: 0.75rem;
  border-radius: 2px;
  padding: 4px 0 4px 8px;
}
.dialog .el-input__prefix .el-icon-time:before {
  content: "";
}
.dialog .el-radio-button--small .el-radio-button__inner {
  padding: 5px 15px;
}
.dialog .el-checkbox-button--small .el-checkbox-button__inner {
  padding: 5px 15px;
}
</style>
<style lang="scss" scoped>
.dialog {
  padding: 10px 20px;
  .dialog-default {
    border-bottom: 1px solid #ebecf0;
    padding: 10px 0;
    text-align: left;
    .filter-items {
      display: inline-flex;
      align-items: center;
      width: 50%;
      margin-bottom: 7px;
      .items-title {
        display: inline-block;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
      .items-option {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        .el-radio-group {
          margin-right: 10px;
        }
        &.time-option {
          position: relative;
        }
        .radio {
          margin: 8px 7px 0 0;
        }
        .datePicker {
          position: absolute;
          top: 100%;
          left: 160px;
          width: 400px;
          padding: 15px;
          box-shadow: 0 1px 2px rgb(0 0 0 / 24%);
          margin-top: 5px;
          z-index: 100;
          background: #fff;
          .label-text {
            font-size: 13px;
            margin-bottom: 10px;
          }
          span {
            margin: 0 10px;
            font-size: 14px;
            color: #333;
            &.confirm {
              display: inline-block;
              padding: 5px 0;
              width: 60px;
              font-size: 14px;
              color: #fff;
              text-align: center;
              background: #fa830c;
              border-radius: 2px;
            }
          }
        }
      }
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
    button {
      border: none;
      outline: none;
      background: #fff;
      font-size: 24px;
    }
  }

  .btns {
    text-align: center;
    margin-top: 10px;
    button {
      border: none;
      outline: none;
      font-size: 14px;
      padding: 0 20px;
      border-radius: 2px;
      height: 32px;
      // &:nth-child(2) {
      //   margin: 0 10px;
      //   background: #e6960c;
      //   color: #fff;
      //   box-shadow: 0 2px 6px 0 rgb(230 150 12 / 60%);
      // }
      // &:nth-child(3) {
      //   color: #666666;
      //   border: 1px solid #dadcdf;
      //   background: #fff;
      // }
    }
    button:first-child {
      background: #5090f1;
      color: #fff;
      box-shadow: 0 2px 6px 0 rgb(80 144 241 / 60%);
    }
  }
}
</style>
