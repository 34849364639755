<template>
  <div class="msglist">
    <div>
      选择查询用户：<el-select
        v-model="checkUserId"
        filterable
        placeholder="请选择查询用户"
        @change="requestData()"
      >
        <el-option
          v-for="user in userInfos"
          :key="user.id"
          :label="user.username"
          :value="user.id"
        >
        </el-option>
      </el-select>

      选择日志类型：<el-select
        v-model="checkOperate"
        filterable
        placeholder="选择日志类型"
        @change="requestData()"
      >
        <el-option
          v-for="operate in operates"
          :key="operate"
          :label="operate"
          :value="operate"
        >
        </el-option>
      </el-select>
    </div>
    <el-row>
      <el-col :span="20">
        <el-table
          :key="timeStamp"
          v-loading="loading"
          :data="tableData"
          style="width: 100%;height:45em;overflow-y:auto;"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column
            prop="userName"
            :formatter="fmtCustomUsername"
            label="客户"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="nickName"
            :formatter="fmtCustomNickname"
            label="操作用户"
            width="100"
          >
          </el-table-column>
          <el-table-column prop="log" label="日志"> </el-table-column>
          <el-table-column prop="operate" label="类型" width="100">
          </el-table-column>
          <el-table-column prop="ip" label="ip" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.ip }}</span
              ><br />
              <span>{{ scope.row.ipCity }}</span
              ><br />
            </template>
          </el-table-column>
          <el-table-column prop="time" label="时间" width="100">
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="pageBean.number"
          :page-size.sync="pageBean.size"
          :page-sizes="[10, 20, 30, 50]"
          layout="total,sizes,prev, pager, next"
          :total.sync="pageBean.totalElements"
        >
        </el-pagination>
      </el-col>
      <el-col :span="4">
        <div style="display:block;"><el-divider>过滤ip地址</el-divider></div>
        <ul style="display:block;">
          <li v-for="(item, index) in filterIp" :key="index">
            <i
              @click="delFitlerIp(item)"
              style="float:right;margin-top:-4px;"
              class="el-icon-close"
            ></i>
            <span v-text="item"></span>
          </li>
        </ul>
        <button @click="addFilterIp()">增加过滤ip</button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeStamp: new Date().getTime(),
      loading: false,
      tableData: [],
      operates: [],
      checkUserId: null,
      checkOperate: "系统登录",
      userInfos: [],
      filterIp: [],
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      }
    };
  },
  mounted() {
    this.initUsers();
    this.initOperates();
    this.refreshFilterIp();
    this.requestData();
  },
  methods: {
    addFilterIp() {
      this.$prompt("请输入过滤ip", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        value = value.trim();
        this.filterIp.push(value);
        localStorage.setItem("filterIp", this.filterIp.join(","));
        this.refreshFilterIp();
        this.requestData();
      });
    },
    delFitlerIp(ip) {
      let position = this.filterIp.indexOf(ip);
      if (position > -1) {
        this.filterIp.splice(position, 1);
        localStorage.setItem("filterIp", this.filterIp.join(","));
        this.refreshFilterIp();
        this.requestData();
      }
    },
    refreshFilterIp() {
      let ipStr = localStorage.getItem("filterIp");
      if (ipStr) {
        this.filterIp = ipStr.split(",");
      }
    },
    fmtCustomUsername(row) {
      let filterUser = this.userInfos.filter(user => {
        return user.id == row.belongUser;
      });
      if (filterUser != null && filterUser.length > 0) {
        return filterUser[0].username;
      }
      return "管理员";
    },
    fmtCustomNickname(row) {
      let filterUser = this.userInfos.filter(user => {
        return user.id == row.belongUser;
      });
      if (filterUser != null && filterUser.length > 0) {
        return filterUser[0].username;
      }
      return "管理员";
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    initUsers() {
      this.$request
        .get("/api/user/list", {
          page: 1,
          size: 500
        })
        .then(res => {
          this.userInfos = res.data.content;
        });
    },
    initOperates() {
      this.$request("/api/user/log/type").then(res => {
        this.operates = res.data;
      });
    },
    requestData() {
      this.loading = true;
      let userId = this.checkUserId == null ? 0 : this.checkUserId;
      let requestParam = {
        page: this.pageBean.number,
        size: this.pageBean.size,
        type: this.checkOperate
      };
      if (this.filterIp.length > 0) {
        requestParam.filterIp = this.filterIp.join(",");
      }
      this.$request.get(`/api/user/${userId}/log`, requestParam).then(res => {
        res.data.number = res.data.number + 1;
        this.pageBean = res.data;
        this.loading = false;
        this.tableData = this.pageBean.content;
        this.tableData.forEach(log => {
          if (log.ip) {
            this.$request(`/api/sys/resource/ip/${log.ip}/address`).then(
              res => {
                log.ipCity = res.data;
                this.timeStamp = new Date().getTime();
              }
            );
          }
        });
      });
    }
  }
};
</script>
