<template>
  <div class="msglist">
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column
        prop="userName"
        :formatter="fmtCustomUsername"
        label="客户"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="nickName"
        :formatter="fmtCustomNickname"
        label="昵称"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="msg" label="留言" width="480"> </el-table-column>
      <el-table-column prop="contact" label="联系方式" width="120">
      </el-table-column>
      <el-table-column prop="pic" label="截图" width="180">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic"
            style="width: 50px; height: 50px"
            :src="'data:image/gif;base64,' + scope.row.pic"
            :preview-src-list="['data:image/gif;base64,' + scope.row.pic]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="time" label="时间"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="delMsg(scope.row.id)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page.sync="pageBean.number"
      :page-size.sync="pageBean.size"
      :page-sizes="[10, 20, 30, 50]"
      layout="total,sizes,prev, pager, next"
      :total.sync="pageBean.totalElements"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      }
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    fmtCustomUsername(row) {
      return row.belong.userName;
    },
    fmtCustomNickname(row) {
      return row.belong.nickName;
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    delMsg(id) {
      //删除用户
      this.$confirm("确认删除该留言?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$request.delete(`/api/sys/resource/msgboard/${id}`).then(() => {
          this.$message.success("删除成功!");
          this.requestData();
        });
      });
    },
    requestData() {
      this.loading = true;
      this.$request
        .get("/api/sys/resource/msgboard", {
          page: this.pageBean.number,
          size: this.pageBean.size
        })
        .then(res => {
          res.data.number = res.data.number + 1;
          this.pageBean = res.data;
          this.loading = false;
          this.tableData = this.pageBean.content;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.msglist {
  width: 80%;
}
</style>
