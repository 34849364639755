<template>
  <div class="collectMsg">
    <div class="header" style="display: flex;justify-content: flex-end;">
      <div style="margin-right: 5%;">
        <el-input
          placeholder="请输入搜索内容"
          v-model="title"
          @change="requestData"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div style="margin-right: 7%;">
        <el-button @click="createReport">
          新建报告
        </el-button>
      </div>
    </div>
    <div class="content">
      <el-table v-loading="loading" :data="this.pageBean.content">
        <el-table-column type="index" align="center" width="80">
        </el-table-column>
        <el-table-column prop="title" label="报告名称" min-width="58">
          <template slot-scope="scope">
            <p
              style="cursor:pointer;font-weight:700"
              v-html="subTitle(scope.row.title)"
            ></p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="10">
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="10">
          <template slot-scope="scope">
            <el-button
              @click="downDoc(scope.row.filePath)"
              type="text"
              size="small"
              >下载</el-button
            >
            <el-button @click="delDoc(scope.row.id)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="pageBean.number"
        :page-size.sync="pageBean.size"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,sizes,prev, pager, next"
        :total.sync="pageBean.totalElements"
      >
      </el-pagination>
    </div>

    <el-dialog width="35%" title="创建简报" :visible.sync="dialogFormVisible">
      <el-form
        v-loading="reportCreating"
        element-loading-text="简报制作中"
        element-loading-spinner="el-icon-loading"
        ref="ruleform"
        :model="form"
        :rules="rules"
        label-width="100px"
        style="text-align: left;"
      >
        <el-form-item label="简报名称：" prop="fileName">
          <el-input v-model="form.fileName"></el-input>
        </el-form-item>
        <el-form-item label="时间范围：">
          <el-col :span="11">
            <el-form-item prop="startTime">
              <el-date-picker
                v-model="form.startTime"
                type="datetime"
                placeholder="开始日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">至</el-col>
          <el-col :span="11">
            <el-form-item prop="endTime">
              <el-date-picker
                v-model="form.endTime"
                type="datetime"
                placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="包含主题：" prop="selectedNodes">
          <el-checkbox-group v-model="form.selectedNodes">
            <el-checkbox
              v-for="(node, index) in nodes"
              :key="index"
              :label="node.id"
              border
              >{{ node.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      form: { selectedNodes: [] },
      selectedNodes: [],
      nodes: [],
      loading: false,
      reportCreating: false,
      title: null,
      dialogFormVisible: false,
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      },
      rules: {
        fileName: [
          { required: true, message: "请输入简报名称", trigger: "blur" },
          { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: "blur" }
        ],
        startTime: [
          {
            type: "date",
            required: true,
            message: "请选择开始日期",
            trigger: "change"
          }
        ],
        endTime: [
          {
            type: "date",
            required: true,
            message: "请选择结束时间",
            trigger: "change"
          }
        ],
        selectedNodes: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个主题",
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    onSubmit() {
      this.$refs["ruleform"].validate(valid => {
        if (valid) {
          let rule = new Array();
          let filterRule = new Array();
          if (this.form.selectedNodes.length > 0) {
            this.nodes.forEach(node => {
              if (this.form.selectedNodes.includes(node.id)) {
                rule.push(node.searchRule);
                if (node.filterRule != "") {
                  filterRule.push(node.filterRule);
                }
              }
            });
          }
          delete this.form.selectedNodes;
          this.form.rule = rule.join(",");
          this.form.type = "topicWord";
          if (filterRule.length > 0) {
            this.form.filterRule = filterRule.join(",");
          }
          this.form.startTime = this.form.startTime.format();
          this.form.endTime = this.form.endTime.format();
          this.reportCreating = true;
          this.$request.post("/api/doc/exportExcel", this.form).then(() => {
            this.reportCreating = false;
            this.dialogFormVisible = false;
            this.requestData();
          });
        } else {
          return false;
        }
      });
    },
    getLeafNodes(nodes) {
      const parentIds = new Set(
        nodes.map(node => node.parent).filter(id => id !== null)
      );
      return nodes.filter(node => !parentIds.has(node.id));
    },
    createReport() {
      this.reportCreating = false;
      this.form = { selectedNodes: [] };
      this.$request.get("/api/doc/ruleNode").then(data => {
        this.nodes = this.getLeafNodes(data.data);
        this.dialogFormVisible = true;
      });
    },
    subTitle(title) {
      if (title.length > 150) {
        title = title.substring(0, 150) + "...";
      }
      return title;
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    downDoc(filepath) {
      window.location.href = filepath;
    },
    delDoc(id) {
      this.$confirm("此操作将删除该简报, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$request.post("/api/data/report/del", { id: id }).then(() => {
          this.$message.success("删除成功");
          this.requestData();
        });
      });
    },
    requestData() {
      this.loading = true;
      this.$request
        .post("/api/data/report/list", {
          page: this.pageBean.number,
          size: this.pageBean.size,
          title: this.title
        })
        .then(res => {
          res.data.number = res.data.number + 1;
          this.pageBean = res.data;
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.collectMsg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  overflow: auto;
  .header {
  }
  .content {
    .el-pagination {
      margin-top: 30px;
    }
  }
}
</style>
