<template>
  <div class="msglist">
    <div class="header">
      <button @click="updateWordDialog()">新增</button>
    </div>
    <div>
      <el-tree :data="data" default-expand-all :props="defaultProps">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span style="margin-right:30px;">{{ node.data.tag }}</span>
          <span>
            <el-button type="text" size="mini" @click="() => appendChild(data)">
              添加子节点
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => updateWordDialog(data)"
            >
              修改
            </el-button>
            <el-button type="text" size="mini" @click="() => delNode(data)">
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>

    <el-dialog title="修改拦截链" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标签">
          <el-input v-model="form.tag"></el-input>
        </el-form-item>

        <el-form-item label="词库">
          <el-input type="textarea" rows="2" v-model="form.wordStr"></el-input>
        </el-form-item>

        <el-form-item label="拦截字段">
          <el-input v-model="form.filterFields"></el-input>
        </el-form-item>

        <el-form-item label="回调数据">
          <el-input v-model="form.returnStr"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="
              dialogFormVisible = false;
              updateWord();
            "
            >修改</el-button
          >
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { duplicateWords } from "@/utils/api.js";
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      data: []
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    appendChild(word) {
      this.form = { parent: { id: word.id } };
      this.dialogFormVisible = true;
    },
    updateWordDialog(word) {
      if (word) {
        this.form = word;
        delete this.form.parent;
      } else {
        this.form = {};
      }
      this.dialogFormVisible = true;
    },
    updateWord() {
      this.form.wordStr = duplicateWords(this.form.wordStr);
      this.$request.post("/api/sys/resource/wordlink", this.form).then(() => {
        this.$message.success("修改成功");
        this.requestData();
      });
    },
    delNode(data) {
      this.$confirm(
        "此操作将删除“" + data.tag + "”该拦截链, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$request
          .delete("/api/sys/resource/wordlink/" + data.id)
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.requestData();
          });
      });
    },
    requestData() {
      this.loading = true;
      this.$request.get("/api/sys/resource/wordlink").then(res => {
        this.data = this.convertToTreeData(res.data);
      });
    },
    convertToTreeData(data) {
      let treeData = new Array();
      for (var node of data) {
        if (node.parent == null) {
          this.findChildNode(node, data);
          treeData.push(node);
        }
      }
      return treeData;
    },

    findChildNode(obj, nodeList) {
      for (var node of nodeList) {
        if (node.parent != null && obj.id == node.parent) {
          this.findChildNode(node, nodeList);
          if (!obj.children) {
            obj.children = new Array();
          }
          obj.children.push(node);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.msglist {
  width: 80%;
}

.header {
  padding-right: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-input {
    width: 300px;
    margin-right: 20px;
  }
  button {
    height: 36px;
    width: 80px;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    background: none;
  }
  // border-bottom: 1px solid #5090f1;
}
</style>
