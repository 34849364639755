<template>
  <div class="msglist">
    <div class="header">
      <el-input
        size="medium"
        v-model="keyword"
        placeholder="请输入用户名"
        suffix-icon="el-icon-search"
        @keyup.enter.native="search"
      >
      </el-input>
      <button @click="(user = {}), (dialogFormVisible = true)">新增</button>
    </div>
    <el-table
      :key="timeStamp"
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="username" label="用户名" width="180">
      </el-table-column>
      <el-table-column prop="nickName" label="昵称" width="180">
      </el-table-column>
      <el-table-column prop="mail" label="邮箱" width="180"> </el-table-column>
      <el-table-column
        prop="mailSends"
        label="当日邮件量"
        width="180"
        :formatter="mailFmt"
      >
      </el-table-column>
      <el-table-column prop="pwdMW" label="密码" width="180"> </el-table-column>
      <el-table-column prop="ai" label="ai" width="180" :formatter="aiFmt">
      </el-table-column>
      <el-table-column prop="expireTime" label="过期时间"> </el-table-column>

      <el-table-column prop="phone" width="180" :formatter="expireTimeFmt">
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="
              user = { ...scope.row };
              user.password = null;
              dialogFormVisible = true;
            "
            type="text"
            size="small"
            >修改</el-button
          >

          <el-button @click="delUser(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page.sync="pageBean.number"
      :page-size.sync="pageBean.size"
      :page-sizes="[10, 20, 30, 50]"
      layout="total,sizes,prev, pager, next"
      :total.sync="pageBean.totalElements"
    >
    </el-pagination>

    <el-dialog
      :title="user.id == null ? '新增用户' : '修改用户'"
      :visible.sync="dialogFormVisible"
    >
      <el-form ref="form" :model="user" label-width="180px">
        <el-form-item label="登录名">
          <el-input v-model="user.username"></el-input>
        </el-form-item>

        <el-form-item label="昵称">
          <el-input v-model="user.nickName"></el-input>
        </el-form-item>

        <el-form-item label="密码">
          <el-input v-model="user.password"></el-input>
        </el-form-item>

        <el-form-item label="邮箱">
          <el-input v-model="user.mail"></el-input>
        </el-form-item>

        <el-form-item label="每日邮件最大发送次数">
          <el-input-number
            v-model="user.mailSendNumLimit"
            :min="0"
            :max="10000"
            label="请填写最大发送次数"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="复制模版">
          <el-input
            type="textarea"
            :rows="2"
            v-model="user.copytemplate"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="ai次数">
          <el-input-number
            v-model="user.aichatnum"
            :min="0"
            :max="1000000"
            label="请填写ai可用次数"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="过期时间">
          <el-date-picker
            v-model="user.expireTime"
            type="datetime"
            placeholder="过期时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="addUser()"
            v-text="user.id == null ? '添加' : '修改'"
          ></el-button>
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeStamp: new Date().getTime(),
      loading: false,
      tableData: [],
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      },
      user: {},
      dialogFormVisible: false,
      keyword: ""
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    mailFmt(row) {
      return row.mailSendedNum + "/" + row.mailSendNumLimit;
    },
    aiFmt(row) {
      return row.aichatusenum + "/" + row.aichatnum;
    },
    expireTimeFmt(row) {
      let expireTime = new Date(row.expireTime);
      let now = new Date();
      let between = expireTime.getTime() - now.getTime();
      if (between < 0) {
        return "账户已过期";
      } else if (between < 7 * 24 * 60 * 60 * 1000) {
        //一星期内到期
        return this.timeFn(expireTime);
      }
      return "";
    },
    timeFn(dateBegin) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateEnd = new Date(); //获取当前时间
      var dateDiff = dateBegin - dateEnd.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);

      var leave4 = leave3 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000);
      var timeFn =
        "距离到期时间：" +
        dayDiff +
        "天 " +
        hours +
        "小时 " +
        minutes +
        " 分钟" +
        seconds +
        " 秒" +
        minseconds +
        "毫秒";
      return timeFn;
    },
    search() {
      this.pageBean.number = 1;
      this.requestData();
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    requestData() {
      this.loading = true;
      this.$request
        .get("/api/user/list", {
          page: this.pageBean.number,
          size: this.pageBean.size,
          username: this.keyword
        })
        .then(res => {
          res.data.number = res.data.number + 1;
          this.pageBean = res.data;
          this.loading = false;
          this.tableData = this.pageBean.content;
          this.tableData.forEach(user => {
            this.$request(`/api/user/${user.id}/config`).then(res => {
              if (res.data.copytemplate) {
                user.copytemplate = res.data.copytemplate;
              }
              if (res.data.pwd) {
                user.pwdMW = res.data.pwd;
              }
              user.aichatnum = res.data.aichatnum;
              user.aichatusenum = res.data.aichatusenum;
              user.mail = res.data.mail;
              user.mailSendNumLimit = res.data.mailSendNumLimit;
              user.mailSendedNum = res.data.mailSendedNum;
              this.timeStamp = new Date().getTime();
            });
          });
          this.loading = false;
        });
    },
    addUser() {
      console.log(this.user);
      if (this.user.expireTime) {
        if (this.user.expireTime instanceof Date) {
          this.user.expireTime = this.user.expireTime.format();
        }
      } else {
        this.$message.warning("请设置用户过期时间!");
        return;
      }

      let copytemplate = this.user.copytemplate;
      let aichatnum = this.user.aichatnum;
      let mail = this.user.mail;
      let mailSendNumLimit = this.user.mailSendNumLimit;
      delete this.user.copytemplate;
      if (this.user.id) {
        //用户修改操作
        this.user = {
          id: this.user.id,
          username: this.user.username,
          nickName: this.user.nickName,
          password: this.user.password,
          expireTime: this.user.expireTime
        };
        // 如果未输入密码 则删除修改密码字段
        if (!this.user.password) {
          delete this.user.password;
        }
      }

      this.$request.post("/api/user", this.user).then(userid => {
        this.$message.success(this.user.id ? "修改成功!" : "添加成功!");
        let postparam = {
          copytemplate: copytemplate,
          aichatnum: aichatnum,
          mail: mail,
          mailSendNumLimit: mailSendNumLimit
        };
        if (this.user.password) {
          postparam.pwd = this.user.password;
        }
        this.$request.post(`/api/user/${userid.data}/config`, postparam);
        this.dialogFormVisible = false;
        this.requestData();
      });
    },
    delUser(row) {
      //删除用户
      this.$confirm(
        "此操作将删除用户“" + row.nickName + "”, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$request.delete(`/api/user/${row.id}`).then(() => {
          this.$message.success("删除成功!");
          this.requestData();
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.msglist {
  width: 100%;
}
.header {
  padding-right: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-input {
    width: 300px;
    margin-right: 20px;
  }
  button {
    height: 36px;
    width: 80px;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    background: none;
  }
  // border-bottom: 1px solid #5090f1;
}
</style>
