import axios from "axios";
import Qs from "qs";
import router from "../router";

const baseUrl = "";
// const baseUrl = "http://127.0.0.1:7070";
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseUrl,
  timeout: 120000 // 超时
});

service.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem("token");
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = token; //请求头加上token
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// response 拦截器
service.interceptors.response.use(null, error => {
  if (error.response.status) {
    switch (error.response.status) {
      case 400:
        return Promise.resolve(error.response);
      case 401:
        router.push({
          name: "Login",
          params: { alertInfo: "身份认证过期，请重新登录!" }
        });
        break;
    }
  }
  return Promise.reject(error);
});

function request(url, method, data) {
  let requsetData = { url: url, method: method };
  if (method == "get") {
    requsetData.params = data;
  } else {
    requsetData.data = Qs.stringify(data, {
      arrayFormat: "indices",
      allowDots: true
    });
  }
  return service.request(requsetData);
}

function get(url, data) {
  return request(url, "get", data);
}

function post(url, data) {
  return request(url, "post", data);
}

export { get, post, service, baseUrl };
