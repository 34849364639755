<template>
  <div class="header">
    <div class="header-box">
      <div class="header-box-logo">
        <img src="../assets/images/header_logo.png" />
      </div>
      <div class="header-box-nav">
        <div
          v-for="item in userMenu"
          class="nav-item"
          :key="item.id"
          @click="changeNav(item)"
        >
          <span
            v-text="item.navText"
            :class="{ border: item.name == currentNav.name }"
          ></span>
        </div>
      </div>
      <div class="header-box-right">
        欢迎您：<span v-text="currentUser.nickName"></span> |
        <span @click="logout">退出</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          navText: "监测",
          name: "Monitor"
        },
        {
          navText: "预警",
          name: "EarlyWarning"
        },
        {
          navText: "收藏",
          name: "CollectMsg"
        },
        {
          navText: "追踪",
          name: "AuthorMonitor"
        },
        {
          navText: "搜索",
          name: "NetSearch"
        },
        {
          navText: "AI",
          name: "ChatAI"
        },
        {
          navText: "简报",
          name: "Report"
        },
        {
          navText: "导航",
          name: "SiteInfoPage"
        },
        {
          navText: "留言",
          name: "MessageBoard"
        },
        {
          navText: "控制面板",
          adminShow: true,
          name: "AdminPanel"
        }
      ],
      currentUser: {},
      currentNav: {}
    };
  },
  methods: {
    isAdmin() {
      //判断是否
      return this.currentUser.admin;
    },
    changeNav(item) {
      this.currentNav = item;
      this.$emit("clickNav", item);
    },
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("currentUser");
      this.$router.push("/login");
    },
    getCurrentUser() {
      if (JSON.parse(sessionStorage.getItem("currentUser"))) {
        this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      }
    },
    handleCommand(command) {
      switch (command) {
        case "a":
          this.alarmTimeDialog = true;
          break;
        case "b":
          this.logout();
          break;
      }
    }
  },
  created: function() {
    this.getCurrentUser();
    this.currentNav = { name: this.$route.name };
  },
  computed: {
    userMenu: function() {
      return this.navList.filter(item => {
        return this.isAdmin() || !item.adminShow;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  .header-box {
    width: 100%;
    height: 100%;
    display: flex;
    box-shadow: 0 -1px 9px 0 gray;
    .header-box-logo {
      width: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 160px;
        height: 80px;
      }
    }
    .header-box-nav {
      flex: 1;
      padding-left: 20px;
      text-align: left;
      line-height: 50px;
      .nav-item {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          color: #5090f1;
        }
        span {
          display: inline-block;
          height: 50px;
          box-sizing: border-box;
          margin: 0 16px;
          &.border {
            color: #5090f1;
            border-bottom: 3px solid #5090f1;
          }
        }
      }
    }
  }
}
</style>
