<template>
  <div class="monitor">
    <div class="leftBar">
      <Tree @clickNode="getCurrentNode" @changeParams="changeParams" />
    </div>
    <div class="mainContent">
      <div class="message-box" ref="scrollbox">
        <div class="content-header">
          <div class="content-header-item content-header-title">
            <div>
              {{ currentNode.name }}
            </div>
            <div
              style="margin-left:10px;color:#5090f1;cursor:pointer"
              @click="showTongji"
            >
              <i class="el-icon-s-data"></i>
            </div>
          </div>

          <div class="content-header-item content-header-search">
            <el-input
              placeholder="请输入内容"
              v-model="keyword"
              size="mini"
              class="input-with-select"
            >
              <el-select
                v-model="searchField"
                slot="prepend"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  label="全文"
                  value="title+content+source"
                ></el-option>
                <el-option label="作者" value="author"></el-option>
                <el-option label="链接" value="url"></el-option>
                <el-option label="来源" value="source"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchKeys"
              ></el-button>
            </el-input>
          </div>
        </div>
        <filter-rule
          :searchKey="searchKey"
          :currentNode="currentNode"
          @changeParams="changeFilterParams"
        ></filter-rule>
        <MessageList
          ref="messageList"
          :checkAll="isCheckAll"
          :params="params"
          @setDocCount="setDocCount"
        ></MessageList>
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :hide-on-single-page="true"
          :page-sizes="[20, 30, 50, 100]"
          layout="total,sizes,prev, pager, next"
          :total="docCount"
        >
        </el-pagination>
      </div>
      <div class="bottom-bar">
        <span>
          <input type="checkbox" v-model="isCheckAll" />
          <span>全部</span>
        </span>
        <el-button size="mini" @click="exportExcel('word')">导出word</el-button>
        <el-button size="mini" @click="exportExcel('excel')"
          >导出excel</el-button
        >
        <el-button size="mini" @click="collectDocs">加入收藏</el-button>
        <el-button size="mini" @click="delDocs">批量删除</el-button>
        <el-button size="mini" @click="flushDocs">刷新</el-button>
      </div>
    </div>

    <el-drawer
      :title="currentNode.name + ' 信息统计'"
      :visible.sync="drawer"
      direction="rtl"
    >
      <div
        v-loading="chart1loadin"
        id="infobar"
        style="width:100%;height:250px"
      ></div>
      <div
        v-loading="chart2loadin"
        id="linebar"
        style="width:100%;height:250px"
      ></div>
      <div
        v-loading="chart3loadin"
        id="authorbar"
        style="width:100%;height:250px"
      ></div>
    </el-drawer>
  </div>
</template>
<script>
import Tree from "./Tree.vue";
import MessageList from "./MessageList.vue";
import filterRule from "./FilterRule.vue";
import Qs from "qs";
import * as echarts from "echarts";

export default {
  components: {
    Tree,
    MessageList,
    filterRule
  },
  data() {
    return {
      currentRule: null,
      currentPage: 1,
      pageSize: 15,
      docCount: 0,
      currentNode: {},
      params: {},
      isCheckAll: false, //是否选中所有数据
      keyword: null,
      searchField: "title+content+source",
      searchKey: null,
      drawer: false,
      chart1loadin: true,
      chart2loadin: true,
      chart3loadin: true
    };
  },

  created() {},

  methods: {
    showTongji() {
      this.drawer = true;
      this.$nextTick(() => {
        console.log(this.params);
        this.chart1loadin = true;
        this.chart2loadin = true;
        this.chart3loadin = true;
        var myChart = echarts.init(document.getElementById("infobar"));
        var lineChart = echarts.init(document.getElementById("linebar"));
        var authorChart = echarts.init(document.getElementById("authorbar"));

        this.$request
          .post("/api/data/tongji/lineTongji?type=0", this.params)
          .then(data => {
            this.chart1loadin = false;
            myChart.setOption({
              title: {
                text: "数据来源统计图"
              },
              tooltip: {
                trigger: "axis"
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                data: data.data.xdata,
                axisLabel: {
                  rotate: -60
                }
              },
              yAxis: {},
              series: [
                {
                  type: "bar",
                  data: data.data.sdata
                }
              ]
            });
          });

        this.$request
          .post("/api/data/tongji/lineTongji?type=1", this.params)
          .then(data => {
            this.chart2loadin = false;

            lineChart.setOption({
              title: {
                text: "总量折线统计图"
              },
              tooltip: {
                trigger: "axis"
              },
              legend: {
                data: data.data.ldata,
                top: "10%"
              },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                type: "category",
                data: data.data.xdata
              },
              yAxis: {
                type: "value"
              },
              series: data.data.sdata
            });
          });

        this.$request
          .post("/api/data/tongji/lineTongji?type=2", this.params)
          .then(data => {
            this.chart3loadin = false;

            authorChart.setOption({
              title: {
                text: "前十作者统计图"
              },
              tooltip: {
                trigger: "axis"
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                data: data.data.xdata,
                axisLabel: {
                  rotate: -60
                }
              },
              yAxis: {},
              series: [
                {
                  type: "bar",
                  data: data.data.sdata
                }
              ]
            });
          });
      });
    },
    flushDocs() {
      this.currentPage = 1;
      this.params = { page: 1, endTime: new Date().format() };
    },
    searchKeys() {
      if (this.keyword) {
        this.searchKey = this.searchField + ":" + this.keyword;
      } else {
        this.searchKey = null;
      }
      //根据关键词搜索数据
    },
    delDocs() {
      //批量删除数据
      let checkIds = this.getCheckIds();
      if (checkIds.length > 0) {
        this.$request.delete("/api/doc/" + checkIds.join(",")).then(() => {
          this.$message.success("删除成功!");
        });
      } else {
        this.$message.warning("请至少选中一条信息!");
      }
    },

    exportExcel(type) {
      //导出数据到excel
      //获取选中数据id
      let checkIds = this.getCheckIds();
      let params = this.getFullQueryParam();
      if (checkIds.length > 0) {
        this.exportDataExcel({
          rule: "id:" + checkIds.join(" "),
          startTime: params.startTime,
          endTime: params.endTime,
          type: type
        });
      } else {
        this.$confirm("此操作将导出该查询的所有数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          params.type = type;
          this.exportDataExcel(params);
        });
      }
    },

    exportDataExcel(data) {
      //数据导出
      this.$request
        .request({
          url: "/api/doc/exportExcel",
          method: "post",
          responseType: "text",
          data: Qs.stringify(data)
        })
        .then(() => {
          this.$message.info("后台文件生成中...!");
        });
    },

    getCheckIds() {
      return this.$refs["messageList"].getCheckIds();
    },

    getFullQueryParam() {
      return this.$refs["messageList"].getFullQueryParam();
    },
    collectDocs() {
      //批量收藏数据
      let checkIds = this.getCheckIds();
      if (checkIds.length > 0) {
        this.$request
          .post("/api/doc/collect", {
            action: "collect",
            ids: checkIds.join(",")
          })
          .then(() => {
            this.$message.success("收藏成功!");
          });
      } else {
        this.$message.warning("请至少选中一条信息!");
      }
    },
    getCurrentNode(node) {
      this.currentNode = { ...node };
    },
    handleCurrentChange(currentPage) {
      this.params = { page: currentPage };
    },
    handleSizeChange(pageSize) {
      this.params = { size: pageSize };
    },
    changeFilterParams(params) {
      if (params.rule && this.params.rule) {
        //包含规则过滤
        let ruleArray = new Array();
        this.currentRule.split(",").forEach(item => {
          params.rule.split(",").forEach(pRule => {
            ruleArray.push(item + "\n" + pRule);
          });
        });
        params.rule = ruleArray.join(",");
      } else {
        params.rule = this.currentRule;
      }

      this.params = params;
      this.isCheckAll = false;
    },
    changeParams(params) {
      this.keyword = null;
      this.searchField = "title+content+source";
      this.currentRule = params.rule;
      this.currentPage = 1;
      params.page = 1;
      params.size = 20;
      this.params = params;
      this.isCheckAll = false;
    },
    setDocCount(data) {
      this.docCount = data;
      this.$refs.scrollbox.scrollTop = 0;
    }
  }
};
</script>
<style>
.monitor .content-header-search .input-with-select {
  width: 450px;
}
.monitor .el-pager li.active {
  background: #5090f1;
  color: #fff;
}
.monitor .el-input--mini .el-input__icon {
  color: #5090f1;
  font-weight: 700;
}
</style>
<style lang="scss" scoped>
.monitor {
  height: 100%;
  display: flex;
  .leftBar {
    width: 10%;
    background: #2a3143;
    overflow: auto;
  }
  .rightBar {
    width: 15%;
    height: 100%;
  }
  .mainContent {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f2f3f7;
    padding: 10px 20px;
    .message-box {
      flex: 1;
      width: 100%;
      height: 100%;
      background: #fff;
      overflow: auto;
      padding-bottom: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
      .content-header {
        margin: 0 20px;
        height: 45px;
        line-height: 45px;
        box-sizing: border-box;
        border-bottom: 1px solid #5090f1;
        display: flex;
        align-items: center;
        .content-header-item {
          flex: 1;
          display: flex;
        }
        .content-header-title {
          text-align: left;
        }
        .content-header-search {
          text-align: right;
        }
        .content-header-filter {
          margin-left: 20px;
          div {
            widows: 60px;
            height: 20px;
            line-height: 20px;
            padding: 0 4px;
            border: 1px solid #dadcdf;
            border-radius: 2px;
            color: #5090f1;
            font-size: 12px;
          }
        }
      }
      .content-extent {
        margin: 10px 20px;
        display: flex;
        .ops-item {
          padding: 2px 5px;
          font-size: 12px;
          margin-right: 10px;
          color: #666666;
          border: 1px solid #dadcdf;
          &.hover {
            color: #5090f1;
            border: 1px solid #5090f1;
          }
        }
      }
      .scroll-content {
        flex: 1;
        overflow-y: auto;
      }
    }
    .bottom-bar {
      z-index: 2;
      height: 40px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      background: #fff;
      box-shadow: 0 -1px 9px 0 rgb(0 0 0 / 24%);
      > span {
        margin-right: 40px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
