<template>
  <div class="early-warning">
    <div class="header">
      <div class="header-left">
        <el-radio-group v-model="timeType" @change="checkTimeType" size="small">
          <el-radio-button label="今天"></el-radio-button>
          <el-radio-button label="24小时"></el-radio-button>
          <el-radio-button label="三天内"></el-radio-button>
          <el-radio-button label="七天内"></el-radio-button>
          <el-radio-button label="自定义"></el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="mini"
          v-if="timeType == '自定义'"
          v-model="rangTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          @change="requestData"
        >
        </el-date-picker>
      </div>

      <button @click="exportExcel">导出</button>
    </div>
    <div class="content">
      <el-table
        v-loading="loading"
        :data="tableData"
        :show-header="false"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" min-width="3"> </el-table-column>
        <el-table-column type="index" min-width="3"> </el-table-column>
        <el-table-column prop="title" label="标题" min-width="64">
          <template slot-scope="scope">
            <p
              style="cursor:pointer;font-weight:700"
              v-html="subTitle(scope.row.title)"
              @click="goDetail(scope.row.id)"
            ></p>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" min-width="10">
        </el-table-column>
        <el-table-column prop="source" label="来源" min-width="10">
          <template slot-scope="scope">
            <el-link
              :href="scope.row.url"
              target="_blank"
              v-text="scope.row.source"
            ></el-link>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="10">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="collectDocDialog(scope.row.id)"
                  icon="el-icon-star-off"
                  >收藏</el-dropdown-item
                >
                <el-dropdown-item icon="el-icon-document-copy"
                  >复制</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-link"
                  @click.native="openUrl(scope.row.url)"
                  >原文</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="delAlarmMsg(scope.row.id)"
                  icon="el-icon-delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="pageBean.number"
        :page-size.sync="pageBean.size"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,sizes,prev, pager, next"
        :total.sync="pageBean.totalElements"
      >
      </el-pagination>
    </div>

    <el-dialog title="标签选择" :visible.sync="dialogFormVisible">
      <el-select
        filterable
        allow-create
        v-model="selectTag"
        placeholder="请选择或输入标签"
      >
        <el-option
          :key="index"
          v-for="(value, key, index) in tags"
          :label="value"
          :value="value"
        ></el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            collectDoc();
            dialogFormVisible = false;
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
import { highStr } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      pageBean: {
        number: 1,
        size: 10,
        totalElements: 0
      },
      multipleSelection: [],
      rangTime: "",
      timeType: "今天",
      tags: [],
      selectTag: "",
      dialogFormVisible: false,
      hotWords: [],
      selectId: null
    };
  },
  mounted() {
    this.requestData();
  },
  methods: {
    highStr(str) {
      if (this.hotWords.length > 0 && str) {
        this.hotWords.forEach(word => {
          let reg = new RegExp(word.words.replaceAll(" ", "|"));
          str = str.replace(reg, regWord => {
            return '<font color="' + word.color + '">' + regWord + "</font>";
          });
        });
      }
      return str;
    },
    openUrl(url) {
      //打开文章链接
      window.open(url);
    },

    collectDoc() {
      //批量收藏数据
      this.$request
        .post("/api/doc/collect", {
          action: "collect",
          ids: this.selectId,
          tag: this.selectTag
        })
        .then(() => {
          this.$message.success("收藏成功!");
        });
    },
    collectDocDialog(id) {
      this.selectId = id;
      this.$request.get("/api/doc/collect", { action: "tags" }).then(res => {
        this.tags = res.data;
        this.dialogFormVisible = true;
      });
    },
    subTitle(title) {
      if (title.length > 200) {
        title = title.substring(0, 200) + "...";
      }
      return highStr(title);
    },
    checkTimeType() {
      if (this.timeType != "自定义") {
        this.requestData();
      }
    },
    goDetail(id) {
      let routeData = this.$router.resolve(`/detail?id=${id}`);
      window.open(routeData.href, "_blank");
      // this.$router.push({ name: "Detail", params: data });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportExcel() {
      //导出为excel
      if (this.multipleSelection.length > 0) {
        let checkIds = [];
        this.multipleSelection.forEach(doc => {
          checkIds.push(doc.id);
        });
        //数据导出
        this.$request
          .request({
            url: "/api/doc/exportExcel",
            method: "post",
            responseType: "text",
            data: Qs.stringify({
              fileName: "舆情数据",
              ids: checkIds.join(",")
            })
          })
          .then(() => {
            this.$message.info("后台文件生成中...!");
          });
      } else {
        this.$message.warning("请至少选中一条信息!");
      }
    },
    handleCurrentChange(currentPage) {
      this.pageBean.number = currentPage;
      this.requestData();
    },
    handleSizeChange(pageSize) {
      this.pageBean.size = pageSize;
      this.requestData();
    },
    delAlarmMsg(id) {
      this.$request.delete("/api/doc/" + id).then(res => {
        console.log(res);
        this.$message.success("删除成功");
        this.tableData = this.tableData.filter(data => {
          return data.id != id;
        });
      });
    },
    setTableData() {
      //设置表格数据
      this.tableData = [];
      this.pageBean.content.map(doc => {
        this.tableData.push({
          title: doc.title,
          time: doc.time,
          source: doc.source,
          id: doc.id,
          url: doc.url
        });
      });
    },
    requestData() {
      this.loading = true;
      let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      let filterRule = "removeUsers:" + currentUser.id;
      let alarmRule = "alarmUsers:" + currentUser.id;

      let startTime = new Date();
      let endTime = new Date();

      switch (this.timeType) {
        case "今天":
          startTime.setHours(0, 0, 0);
          break;
        case "24小时":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 1);
          break;
        case "三天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 3);
          break;
        case "七天内":
          startTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 7);
          break;
        case "自定义":
          startTime = this.rangTime[0];
          endTime = this.rangTime[1];
          if (!startTime || !endTime) {
            //未选择时间 默认选中今天
            this.timeType = "今天";
            this.requestData();
          }

          break;
      }
      this.$request
        .post("/api/doc", {
          filterRule: filterRule,
          rule: alarmRule,
          page: this.pageBean.number,
          size: this.pageBean.size,
          startTime: startTime.format(),
          endTime: endTime.format(),
          timeField: "time",
          field: "title"
        })
        .then(res => {
          res.data.number = res.data.number + 1;
          this.pageBean = res.data;
          this.setTableData();
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.early-warning .content-header-search .el-input {
  width: 200px !important;
}
.early-warning .dialog {
  top: 0px;
  right: -320px;
}
.early-warning .el-radio-button--small .el-radio-button__inner {
  padding: 5px 15px;
}
/* .early-warning .el-input__inner {
  width: 180px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  font-size: 0.75rem;
  border-radius: 2px;
  padding: 4px 0 4px 8px;
} */
</style>
<style lang="scss" scoped>
.early-warning {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  //   display: flex;
  .header {
    padding: 0 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
    }
    button {
      height: 36px;
      width: 80px;
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      background: none;
    }
    .el-range-editor.el-input__inner {
      margin-left: 10px;
    }
  }
  .content {
    padding: 0 40px;
    .el-pagination {
      margin-top: 30px;
    }
    //   position: relative;
    //   width: 70%;
    //   height: 100%;
    //   margin: 20px 10px 0 200px;
    //   background: #fff;
    .content-header {
      margin: 0 20px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      border-bottom: 1px solid #5090f1;
      display: flex;
      align-items: center;
      .content-header-item {
        flex: 1;
      }
      .content-header-title {
        text-align: left;
      }
      .content-header-search {
        text-align: right;
      }
      .content-header-filter {
        margin-left: 20px;
        div {
          widows: 60px;
          height: 20px;
          line-height: 20px;
          padding: 0 4px;
          border: 1px solid #dadcdf;
          border-radius: 2px;
          color: #5090f1;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
