<template>
  <div>
    <el-tree :data="data" default-expand-all :props="defaultProps">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span style="margin-right:30px;">{{ node.data.name }}</span>
        <span>
          <el-button type="text" size="mini" @click="() => appendChild(data)">
            添加子节点
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => updateNodeDialog(data)"
          >
            修改
          </el-button>
          <el-button type="text" size="mini" @click="() => delNode(data)">
            删除
          </el-button>
        </span>
      </span>
    </el-tree>

    <el-dialog title="修改主题" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="checkNode" label-width="80px">
        <el-form-item label="主题名称">
          <el-input v-model="checkNode.name"></el-input>
        </el-form-item>

        <el-form-item label="查询条件">
          <el-input
            type="textarea"
            rows="2"
            v-model="checkNode.searchRule"
          ></el-input>
        </el-form-item>

        <el-form-item label="过滤条件">
          <el-input
            type="textarea"
            rows="2"
            v-model="checkNode.filterRule"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="
              dialogFormVisible = false;
              updateNode();
            "
            >修改</el-button
          >
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <button @click="updateNodeDialog()">添加</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      checkNode: {},
      dialogFormVisible: false,
      defaultProps: {
        children: "children",
        label: "label"
      }
    };
  },
  mounted() {
    this.resetTreeNode();
  },
  methods: {
    appendChild(data) {
      this.checkNode = { parent: { id: data.id } };
      console.log(data);
      this.updateNodeDialog(this.checkNode);
    },
    delNode(data) {
      this.$confirm(
        "此操作将删除“" + data.name + "”节点以及其下子节点, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$request.delete("/api/doc/ruleNode/" + data.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.resetTreeNode();
        });
      });
    },
    updateNodeDialog(data) {
      console.log(data);
      if (data) {
        this.checkNode = data;
        delete this.checkNode.children;
        delete this.checkNode.belong;
        if (this.checkNode.id) {
          delete this.checkNode.parent;
        }
      } else {
        this.checkNode = {};
      }
      this.dialogFormVisible = true;
    },
    updateNode() {
      this.$request
        .post("/api/sys/resource/specialDocNode", this.checkNode)
        .then(() => {
          this.$message.success("修改成功");
          this.resetTreeNode();
        });
    },
    resetTreeNode() {
      //重置节点
      this.$request.get("/api/sys/resource/specialDocNode").then(res => {
        this.data = this.convertToTreeData(res.data);
      });
    },
    convertToTreeData(data) {
      let treeData = new Array();
      for (var node of data) {
        if (node.parent == null) {
          this.findChildNode(node, data);
          treeData.push(node);
        }
      }
      return treeData;
    },

    findChildNode(obj, nodeList) {
      for (var node of nodeList) {
        if (node.parent != null && obj.id == node.parent) {
          this.findChildNode(node, nodeList);
          if (!obj.children) {
            obj.children = new Array();
          }
          obj.children.push(node);
        }
      }
    }
  }
};
</script>
